import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { toast } from 'sonner';
import { Loader2, Plus, Trash2 } from 'lucide-react';
import { Switch } from '@/components/ui/switch';
import { supabase } from '@/integrations/supabase/client';
import { Database } from '@/lib/supabase/database.types.ts';

// Define type based on DB schema (assuming table name is 'onboarding_steps')
type OnboardingStep = Database['public']['Tables']['onboarding_steps']['Row'];

export function OnboardingManagement() {
  const queryClient = useQueryClient();

  // Fetch steps from Supabase database
  const { data: steps, isLoading } = useQuery({
    queryKey: ['onboarding-steps'],
    queryFn: async (): Promise<OnboardingStep[]> => {
      const { data, error } = await supabase
        .from('onboarding_steps')
        .select('*')
        .order('step_number', { ascending: true }); // Order by step_number

      if (error) {
        console.error('Fel vid hämtning av steg från DB:', error);
        toast.error('Kunde inte hämta onboarding-steg från databasen.');
        throw error;
      }
      return data || [];
    },
  });

  // --- Mutation för att UPPDATERA ett steg i Supabase ---
  const updateStep = useMutation({
    mutationFn: async (stepToUpdate: Partial<OnboardingStep> & Pick<OnboardingStep, 'id'>) => {
      // Destructure för att bara skicka med uppdaterade fält (exklusive id och step_number som inte ska ändras här)
      const { id, title, description, image_url, active } = stepToUpdate;
      const { data, error } = await supabase
        .from('onboarding_steps')
        .update({ title, description, image_url, active })
        .eq('id', id)
        .select()
        .single();

      if (error) {
        console.error('Supabase update error:', error);
        throw error;
      }
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['onboarding-steps'] });
      toast.success('Onboarding-steg uppdaterat');
    },
    onError: error => {
      console.error('Uppdateringsfel:', error);
      toast.error('Kunde inte uppdatera onboarding-steg');
    },
  });

  // --- Mutation för att TA BORT ett steg från Supabase ---
  const deleteStep = useMutation({
    mutationFn: async (id: string) => {
      const { error } = await supabase.from('onboarding_steps').delete().eq('id', id);

      if (error) {
        console.error('Supabase delete error:', error);
        throw error;
      }
      // Vid borttagning kan vi behöva uppdatera step_number för de återstående.
      // Detta görs enklast genom att invalidera och låta queryn hämta den nya ordningen,
      // men om det behövs direkt kan man göra en edge function eller ytterligare anrop.
      return id; // Returnera ID för borttaget steg
    },
    onSuccess: () => {
      // Invalidera queryn för att hämta den uppdaterade, potentiellt omnumrerade listan
      queryClient.invalidateQueries({ queryKey: ['onboarding-steps'] });
      toast.success('Onboarding-steg borttaget');
    },
    onError: error => {
      console.error('Borttagningsfel:', error);
      toast.error('Kunde inte ta bort onboarding-steg');
    },
  });

  // --- Mutation för att LÄGGA TILL ett nytt steg i Supabase ---
  const addStepMutation = useMutation({
    mutationFn: async (newStepData: Omit<OnboardingStep, 'id' | 'created_at'>) => {
      const { data, error } = await supabase
        .from('onboarding_steps')
        .insert([newStepData]) // Skicka som en array
        .select()
        .single();

      if (error) {
        console.error('Supabase insert error:', error);
        throw error;
      }
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['onboarding-steps'] });
      toast.success('Nytt onboarding-steg tillagt');
    },
    onError: error => {
      console.error('Add step error:', error);
      toast.error('Kunde inte lägga till nytt steg');
    },
  });

  // Hantera tillägg av nytt steg
  const handleAddStep = () => {
    const currentSteps = Array.isArray(steps) ? steps : [];
    const nextStepNumber =
      currentSteps.length > 0 ? Math.max(...currentSteps.map(s => s.step_number)) + 1 : 1;
    addStepMutation.mutate({
      title: 'Nytt steg',
      description: 'Beskrivning...',
      image_url: '', // Sätt till tom sträng eller defaultbild
      step_number: nextStepNumber,
      active: true,
    });
  };

  // Komponent för att redigera ett steg
  const StepEditor = ({ step }: { step: OnboardingStep }) => {
    const [formState, setFormState] = useState({
      title: step.title,
      description: step.description,
      image_url: step.image_url,
      active: step.active,
    });

    const handleInputChange = (key: string, value: string | boolean) => {
      setFormState(prev => ({ ...prev, [key]: value }));
    };

    const handleSave = () => {
      updateStep.mutate({
        id: step.id,
        ...formState,
        step_number: step.step_number,
      });
    };

    return (
      <Card className="mb-4">
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium">
            Steg {step.step_number}: {step.title}
          </CardTitle>
          <Button variant="ghost" size="icon" onClick={() => deleteStep.mutate(step.id)}>
            <Trash2 className="h-4 w-4" />
          </Button>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor={`title-${step.id}`}>Titel</Label>
            <Input
              id={`title-${step.id}`}
              value={formState.title}
              onChange={e => handleInputChange('title', e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor={`description-${step.id}`}>Beskrivning</Label>
            <Textarea
              id={`description-${step.id}`}
              value={formState.description}
              onChange={e => handleInputChange('description', e.target.value)}
              rows={3}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor={`image-${step.id}`}>Bild URL</Label>
            <Input
              id={`image-${step.id}`}
              value={formState.image_url}
              onChange={e => handleInputChange('image_url', e.target.value)}
              placeholder="/images/onboarding/... eller https://..."
            />
          </div>
          <div className="flex items-center space-x-2 pt-2">
            <Switch
              id={`active-${step.id}`}
              checked={formState.active}
              onCheckedChange={checked => handleInputChange('active', checked)}
            />
            <Label htmlFor={`active-${step.id}`}>Aktiv</Label>
          </div>
          <Button onClick={handleSave} disabled={updateStep.isPending} className="w-full">
            {updateStep.isPending ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Sparar...
              </>
            ) : (
              'Spara ändringar'
            )}
          </Button>
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="bg-card rounded-lg shadow p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Onboarding-hantering</h2>
        <Button onClick={handleAddStep} disabled={addStepMutation.isPending}>
          <Plus className="mr-2 h-4 w-4" />
          Lägg till steg
        </Button>
      </div>

      <div className="space-y-4">
        {isLoading ? (
          <div className="flex items-center justify-center p-8">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        ) : (
          <>
            {(Array.isArray(steps) ? steps : []).map(step => (
              <StepEditor key={step.id} step={step} />
            ))}

            {(!steps || steps.length === 0) && (
              <div className="text-center p-8 border border-dashed rounded-lg">
                <p className="text-muted-foreground">
                  Inga onboarding-steg har lagts till än. Klicka på "Lägg till steg" för att komma
                  igång.
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

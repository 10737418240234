import { motion } from 'framer-motion';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { BackButton } from '@/components/ui/back-button';
import { useScrollToTop } from '@/hooks/useScrollToTop';

export default function Shipping() {
  useScrollToTop();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-background"
    >
      <div className="max-w-3xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <BackButton />
        <h1 className="text-3xl font-heading mb-8">Shipping Information</h1>

        <div className="prose prose-gray max-w-none">
          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Delivery Options</h2>
            <div className="bg-card rounded-lg p-6">
              <div className="grid gap-4">
                <div className="p-4 border border-border rounded-md">
                  <h3 className="font-medium mb-2">Standard Shipping</h3>
                  <p className="text-muted-foreground text-sm">3-5 business days</p>
                  <p className="text-primary font-medium mt-2">99 kr</p>
                </div>

                <div className="p-4 border border-border rounded-md">
                  <h3 className="font-medium mb-2">Express Shipping</h3>
                  <p className="text-muted-foreground text-sm">1-2 business days</p>
                  <p className="text-primary font-medium mt-2">199 kr</p>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Shipping Regions</h2>
            <p className="text-muted-foreground mb-4">
              We currently ship to the following regions:
            </p>
            <ul className="list-disc pl-6 text-muted-foreground space-y-2">
              <li>Sweden (Domestic)</li>
              <li>Norway</li>
              <li>Denmark</li>
              <li>Finland</li>
              <li>European Union</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Frequently Asked Questions</h2>
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="tracking">
                <AccordionTrigger>How can I track my order?</AccordionTrigger>
                <AccordionContent>
                  Once your order ships, you'll receive a tracking number via email. You can use
                  this number to track your package on our shipping partner's website.
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="processing">
                <AccordionTrigger>How long does order processing take?</AccordionTrigger>
                <AccordionContent>
                  Orders are typically processed within 1-2 business days. During peak seasons or
                  sales events, processing may take up to 3 business days.
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="international">
                <AccordionTrigger>What about international shipping?</AccordionTrigger>
                <AccordionContent>
                  For international orders outside our standard shipping regions, please contact our
                  customer service team for availability and rates.
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="customs">
                <AccordionTrigger>Are there additional customs fees?</AccordionTrigger>
                <AccordionContent>
                  Orders shipped outside the EU may be subject to import duties and taxes. These
                  fees are the responsibility of the customer and are not included in the shipping
                  cost.
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </section>

          <section>
            <h2 className="text-2xl font-heading mb-4">Contact Shipping Support</h2>
            <p className="text-muted-foreground">
              For any shipping-related questions, please email us at{' '}
              <a href="mailto:shipping@pundeez.com" className="text-primary hover:underline">
                shipping@pundeez.com
              </a>{' '}
              or visit our{' '}
              <a href="/contact" className="text-primary hover:underline">
                contact page
              </a>
              .
            </p>
          </section>
        </div>
      </div>
    </motion.div>
  );
}

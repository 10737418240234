// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from '@/lib/supabase/database.types';

// Använd miljövariabler med fallback till de hårdkodade värdena
const SUPABASE_URL =
  import.meta.env.VITE_SUPABASE_URL || 'https://sfshutidfktywucuduix.supabase.co';
const SUPABASE_ANON_KEY =
  import.meta.env.VITE_SUPABASE_ANON_KEY ||
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNmc2h1dGlkZmt0eXd1Y3VkdWl4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg4NTg1OTYsImV4cCI6MjA1NDQzNDU5Nn0.4_TedzjZOLJC_mdjlFJvS3mf9Xbv4U_CPDnlahSPYPs';

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_ANON_KEY);

import { motion } from 'framer-motion';
// import { cn } from '@/lib/utils'; // Removed unused import
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { useEffect, useState } from 'react';

/**
 * BackgroundImageSection - En enkel sektion med endast en bakgrundsbild
 *
 * Denna komponent:
 * - Visar endast en bakgrundsbild som täcker hela viewporten
 * - Hämtar bakgrundsbildens URL från databasen
 * - Har inga UI-element
 * - Använder Framer Motion för enkel fade-in animation
 * - Kan användas som visuell avgränsare mellan andra innehållsrika sektioner
 * - Använder olika bilder för mobil och desktop för optimal visning
 */
const BackgroundImageSection = () => {
  // State för att hålla reda på om det är en mobil enhet
  const [isMobile, setIsMobile] = useState(false);

  // Uppdatera isMobile-state baserat på skärmstorlek
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Kör en gång vid start
    checkIfMobile();

    // Lägg till event listener för att uppdatera vid skärmstorlek-ändringar
    window.addEventListener('resize', checkIfMobile);

    // Rensa event listener vid unmount
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Hämta innehåll från databasen med React Query
  const { data: contents = [] } = useQuery({
    queryKey: ['section-contents', 'background'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('section_content')
        .select('*')
        .eq('section_id', 'background');

      if (error) throw error;
      return data;
    },
  });

  // Hjälpfunktion för att hämta innehåll baserat på nyckel
  const getContent = (key: string) => {
    const content = contents.find(c => c.content_key === key);
    return content?.content_value || '';
  };

  // Hämta bakgrundsbilder från databasen eller använd standardvärden
  const desktopImage = getContent('image_url') || '/images/background-full.jpg';
  const mobileImage = getContent('mobile_image_url') || desktopImage; // Använd desktop-bilden som fallback

  // Välj rätt bild baserat på skärmstorlek
  const backgroundImage = isMobile ? mobileImage : desktopImage;

  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.5, ease: 'easeOut' }}
      viewport={{ once: true }}
      className="relative h-screen w-full overflow-hidden snap-start"
    >
      <motion.img
        initial={{ scale: 1.05 }}
        animate={{ scale: 1 }}
        transition={{ duration: 2, ease: 'easeOut' }}
        src={backgroundImage}
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover"
        style={{ objectPosition: 'center center' }}
      />
    </motion.section>
  );
};

export default BackgroundImageSection;

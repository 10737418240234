import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

interface OrderDetails {
  id: string;
  customer_name: string;
  total_amount: number;
  status: string;
}

export default function CheckoutSuccess() {
  const navigate = useNavigate();
  const [order, setOrder] = useState<OrderDetails | null>(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const orderId = localStorage.getItem('current_order_id');
      if (!orderId) {
        toast.error('No order information found');
        navigate('/');
        return;
      }

      try {
        const { data, error } = await supabase
          .from('orders')
          .select('id, customer_name, total_amount, status')
          .eq('id', orderId)
          .single();

        if (error) throw error;
        if (!data) {
          toast.error('Order not found');
          navigate('/');
          return;
        }

        setOrder(data);
        localStorage.removeItem('current_order_id');
      } catch (error) {
        console.error('Error fetching order:', error);
        toast.error('Failed to load order details');
        navigate('/');
      }
    };

    fetchOrderDetails();
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="max-w-md w-full mx-auto p-8">
        <div className="bg-white rounded-lg shadow-lg p-6 text-center space-y-4">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto">
            <CheckCircle className="w-8 h-8 text-green-600" />
          </div>

          <h1 className="text-2xl font-semibold">Order Confirmed!</h1>

          {order && (
            <div className="text-gray-600 space-y-2">
              <p>Thank you for your order, {order.customer_name}!</p>
              <p>Order ID: {order.id}</p>
            </div>
          )}

          <p className="text-gray-600">
            We'll send you a confirmation email with your order details shortly.
          </p>

          <Button className="w-full" onClick={() => navigate('/')}>
            Continue Shopping
          </Button>
        </div>
      </div>
    </div>
  );
}

import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/useAuth';
import { ThemeSettings, AdminTabs } from '@/components/admin';

export default function AdminDashboard() {
  const { signOut } = useAuth();

  return (
    <div className="min-h-screen p-8 bg-background">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Admin Dashboard</h1>
          <Button onClick={signOut}>Sign Out</Button>
        </div>

        <div className="space-y-8">
          <ThemeSettings />
          <AdminTabs />
        </div>
      </div>
    </div>
  );
}

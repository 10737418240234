import { motion } from 'framer-motion';
import { BackButton } from '@/components/ui/back-button';
import { useScrollToTop } from '@/hooks/useScrollToTop';

export default function Terms() {
  useScrollToTop();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-background"
    >
      <div className="max-w-3xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <BackButton />
        <h1 className="text-3xl font-heading mb-8">Terms & Conditions</h1>

        <div className="prose prose-gray max-w-none">
          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Agreement to Terms</h2>
            <p className="text-muted-foreground mb-4">
              By accessing or using our website, you agree to be bound by these Terms and Conditions
              and our Privacy Policy. If you disagree with any part of these terms, you may not
              access our website or use our services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Intellectual Property</h2>
            <p className="text-muted-foreground mb-4">
              Our website and its original content, features, and functionality are owned by Pundeez
              and are protected by international copyright, trademark, patent, trade secret, and
              other intellectual property laws.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">User Account Responsibilities</h2>
            <ul className="list-disc pl-6 text-muted-foreground space-y-2">
              <li>You are responsible for maintaining the confidentiality of your account</li>
              <li>You agree to accept responsibility for all activities under your account</li>
              <li>You must be 18 years or older to use our services</li>
              <li>You must provide accurate and complete information</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Order Acceptance</h2>
            <p className="text-muted-foreground mb-4">
              We reserve the right to refuse any order you place with us. We may, in our sole
              discretion, limit or cancel quantities purchased per person, per household, or per
              order.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Product Descriptions</h2>
            <p className="text-muted-foreground mb-4">
              We strive to be as accurate as possible with our product descriptions. However, we do
              not warrant that product descriptions or other content is accurate, complete,
              reliable, current, or error-free.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Pricing and Payment</h2>
            <p className="text-muted-foreground mb-4">
              All prices are subject to change without notice. We reserve the right to modify or
              discontinue any product without notice. We shall not be liable to you or any third
              party for any modification, price change, or discontinuance.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-heading mb-4">Changes to Terms</h2>
            <p className="text-muted-foreground">
              We reserve the right to update or change our Terms and Conditions at any time. Your
              continued use of our services following any changes indicates your acceptance of such
              changes.
            </p>
          </section>
        </div>
      </div>
    </motion.div>
  );
}

import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { Trash2 } from 'lucide-react';

// Avatar type definition
interface Avatar {
  id: string;
  name: string;
  image_url: string;
  created_at: string;
}

// Minimal implementation of AvatarManagement component
export function AvatarManagement() {
  const [newAvatarName, setNewAvatarName] = useState('');
  const [newAvatarImageUrl, setNewAvatarImageUrl] = useState('');
  const queryClient = useQueryClient();

  // Fetch avatars from database
  const { data: avatars, isLoading } = useQuery({
    queryKey: ['avatars'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('avatars')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data as Avatar[];
    },
  });

  // Add new avatar
  const addAvatarMutation = useMutation({
    mutationFn: async (newAvatar: { name: string; image_url: string }) => {
      const { data, error } = await supabase.from('avatars').insert([newAvatar]).select();
      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['avatars'] });
      setNewAvatarName('');
      setNewAvatarImageUrl('');
      toast.success('Avatar added successfully');
    },
    onError: error => {
      console.error('Error adding avatar:', error);
      toast.error('Failed to add avatar');
    },
  });

  // Delete avatar
  const deleteAvatarMutation = useMutation({
    mutationFn: async (id: string) => {
      const { error } = await supabase.from('avatars').delete().eq('id', id);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['avatars'] });
      toast.success('Avatar deleted successfully');
    },
    onError: error => {
      console.error('Error deleting avatar:', error);
      toast.error('Failed to delete avatar');
    },
  });

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!newAvatarName || !newAvatarImageUrl) {
      toast.error('Please fill in all fields');
      return;
    }
    addAvatarMutation.mutate({ name: newAvatarName, image_url: newAvatarImageUrl });
  };

  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-semibold">Avatar Management</h2>

      {/* Add new avatar form */}
      <form onSubmit={handleSubmit} className="space-y-4 p-4 border rounded-lg">
        <h3 className="text-lg font-medium">Add New Avatar</h3>

        <div className="space-y-2">
          <label htmlFor="avatar-name" className="block text-sm font-medium">
            Avatar Name
          </label>
          <input
            id="avatar-name"
            type="text"
            value={newAvatarName}
            onChange={e => setNewAvatarName(e.target.value)}
            className="w-full p-2 border rounded-md"
            placeholder="Enter avatar name"
          />
        </div>

        <div className="space-y-2">
          <label htmlFor="avatar-image-url" className="block text-sm font-medium">
            Image URL
          </label>
          <input
            id="avatar-image-url"
            type="text"
            value={newAvatarImageUrl}
            onChange={e => setNewAvatarImageUrl(e.target.value)}
            className="w-full p-2 border rounded-md"
            placeholder="Enter image URL"
          />
        </div>

        {newAvatarImageUrl && (
          <div className="mt-2">
            <p className="text-sm font-medium mb-2">Preview:</p>
            <img
              src={newAvatarImageUrl}
              alt="Avatar Preview"
              className="w-24 h-24 object-cover rounded-md"
              onError={e => {
                (e.target as HTMLImageElement).src = '/placeholder.png';
                toast.error('Invalid image URL');
              }}
            />
          </div>
        )}

        <button
          type="submit"
          className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90"
          disabled={addAvatarMutation.isPending}
        >
          {addAvatarMutation.isPending ? 'Adding...' : 'Add Avatar'}
        </button>
      </form>

      {/* Avatar list */}
      <div className="space-y-4">
        <h3 className="text-lg font-medium">Existing Avatars</h3>

        {isLoading ? (
          <p>Loading avatars...</p>
        ) : !avatars?.length ? (
          <p>No avatars found.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {avatars.map(avatar => (
              <div key={avatar.id} className="p-4 border rounded-lg flex items-start space-x-4">
                <img
                  src={avatar.image_url}
                  alt={avatar.name}
                  className="w-16 h-16 object-cover rounded-md"
                  onError={e => {
                    (e.target as HTMLImageElement).src = '/placeholder.png';
                  }}
                />
                <div className="flex-1">
                  <h4 className="font-medium">{avatar.name}</h4>
                </div>
                <button
                  onClick={() => {
                    if (window.confirm(`Are you sure you want to delete "${avatar.name}"?`)) {
                      deleteAvatarMutation.mutate(avatar.id);
                    }
                  }}
                  className="text-destructive hover:text-destructive/80"
                  title="Delete avatar"
                >
                  <Trash2 size={18} />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AvatarManagement;

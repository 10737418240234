import { motion } from 'framer-motion';
import OverlayControls from '@/components/OverlayControls';
import HeroSection from '@/components/HeroSection';
import CatalogueSection from '@/components/CatalogueSection';
import Model2 from '@/components/Model2';
import ProductSection from '@/components/ProductSection';
import GroupSection1 from '@/components/GroupSection1';
import GroupSection2 from '@/components/GroupSection2';
import BackgroundImageSection from '@/components/BackgroundImageSection';
import Footer from '@/components/Footer';
import { useEffect, useRef, useState } from 'react';

const Index = () => {
  const [showGenderControls, setShowGenderControls] = useState(true);
  const [showSizeControls, setShowSizeControls] = useState(true);
  const productSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const intersectionRatio = entry.intersectionRatio;

        if (intersectionRatio > 0.3) {
          setShowGenderControls(false);
          setShowSizeControls(false);
        } else {
          setShowGenderControls(true);
          setShowSizeControls(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      }
    );

    if (productSectionRef.current) {
      observer.observe(productSectionRef.current);
    }

    return () => {
      if (productSectionRef.current) {
        observer.unobserve(productSectionRef.current);
      }
    };
  }, []);

  // Animation variants for sections - Removed y offset
  const sectionAnimation = {
    initial: { opacity: 0 /*, y: 50 */ },
    whileInView: { opacity: 1 /*, y: 0 */ },
    transition: { duration: 0.6, ease: 'easeOut' },
  };

  const viewportOptions = { amount: 0.2 };

  return (
    <div className="min-h-screen bg-background snap-container">
      <OverlayControls
        showGenderControls={showGenderControls}
        showSizeControls={showSizeControls}
      />

      <motion.div
        className="snap-section-wrapper"
        initial={sectionAnimation.initial}
        whileInView={sectionAnimation.whileInView}
        transition={sectionAnimation.transition}
        viewport={viewportOptions}
      >
        <div className="snap-section-content">
          <HeroSection />
        </div>
      </motion.div>

      <motion.div
        className="snap-section-wrapper"
        initial={sectionAnimation.initial}
        whileInView={sectionAnimation.whileInView}
        transition={sectionAnimation.transition}
        viewport={viewportOptions}
      >
        <div className="snap-section-content">
          <CatalogueSection />
        </div>
      </motion.div>

      <motion.div
        className="snap-section-wrapper"
        initial={sectionAnimation.initial}
        whileInView={sectionAnimation.whileInView}
        transition={sectionAnimation.transition}
        viewport={viewportOptions}
      >
        <div className="w-full h-full">
          <Model2 />
        </div>
      </motion.div>

      <motion.div
        className="snap-section-wrapper"
        initial={sectionAnimation.initial}
        whileInView={sectionAnimation.whileInView}
        transition={sectionAnimation.transition}
        viewport={viewportOptions}
      >
        <div className="snap-section-content">
          <GroupSection1 />
        </div>
      </motion.div>

      <motion.div
        className="snap-section-wrapper"
        initial={sectionAnimation.initial}
        whileInView={sectionAnimation.whileInView}
        transition={sectionAnimation.transition}
        viewport={viewportOptions}
      >
        <div className="snap-section-content">
          <GroupSection2 />
        </div>
      </motion.div>

      <motion.div
        className="snap-section-wrapper"
        initial={sectionAnimation.initial}
        whileInView={sectionAnimation.whileInView}
        transition={sectionAnimation.transition}
        viewport={viewportOptions}
      >
        <div className="snap-section-content">
          <BackgroundImageSection />
        </div>
      </motion.div>

      <motion.div
        ref={productSectionRef}
        className="snap-section-wrapper"
        initial={sectionAnimation.initial}
        whileInView={sectionAnimation.whileInView}
        transition={sectionAnimation.transition}
        viewport={viewportOptions}
      >
        <div className="snap-section-content">
          <ProductSection />
        </div>
      </motion.div>

      <motion.div
        className="snap-section-wrapper"
        initial={sectionAnimation.initial}
        whileInView={sectionAnimation.whileInView}
        transition={sectionAnimation.transition}
        viewport={viewportOptions}
      >
        <div className="snap-section-content">
          <Footer />
        </div>
      </motion.div>
    </div>
  );
};

export default Index;

import { useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { useToast } from '@/components/ui/use-toast';

export function ThemeSettings() {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  // Query theme settings
  const { data: settings } = useQuery({
    queryKey: ['app-settings'],
    queryFn: async () => {
      const { data, error } = await supabase.from('app_settings').select('*').single();

      if (error) throw error;
      return data;
    },
  });

  // Update theme mutation
  const updateTheme = useMutation({
    mutationFn: async (darkMode: boolean) => {
      const { error } = await supabase
        .from('app_settings')
        .update({ dark_mode: darkMode })
        .eq('id', settings?.id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['app-settings'] });
      toast({
        title: 'Theme updated',
        description: 'The application theme has been updated successfully.',
      });
    },
    onError: error => {
      toast({
        title: 'Error updating theme',
        description: 'There was an error updating the theme. Please try again.',
        variant: 'destructive',
      });
    },
  });

  // Apply theme changes
  useEffect(() => {
    if (settings?.dark_mode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [settings?.dark_mode]);

  return (
    <div className="bg-card rounded-lg shadow p-6 space-y-6">
      <div className="flex items-center justify-between">
        <div className="space-y-0.5">
          <h2 className="text-xl font-semibold">Theme Settings</h2>
          <p className="text-muted-foreground">
            Toggle between light and dark mode for the entire application
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <Label htmlFor="dark-mode">Dark Mode</Label>
          <Switch
            id="dark-mode"
            checked={settings?.dark_mode || false}
            onCheckedChange={checked => updateTheme.mutate(checked)}
          />
        </div>
      </div>
    </div>
  );
}

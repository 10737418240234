import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { ShippingSettings } from '@/types/shipping';

export function ShippingManagement() {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [newPrice, setNewPrice] = useState<string>('');
  const [newFreeShippingThreshold, setNewFreeShippingThreshold] = useState<string>('');

  const { data: shippingSettings, isLoading } = useQuery({
    queryKey: ['shipping-settings'],
    queryFn: async () => {
      const { data, error } = await supabase.from('shipping_settings').select('*').maybeSingle();

      if (error) {
        console.error('Error fetching shipping settings:', error);
        throw error;
      }
      return (data as ShippingSettings | null) ?? { price: 99 };
    },
    staleTime: 0, // Always fetch fresh data
  });

  const updateShipping = useMutation({
    mutationFn: async (data: { price: number; freeShippingThreshold?: number }) => {
      const { price, freeShippingThreshold } = data;
      // If no settings exist or id is undefined, create a new row
      if (!shippingSettings?.id) {
        const { error } = await supabase
          .from('shipping_settings')
          .insert({ price, free_shipping_threshold: freeShippingThreshold });
        if (error) throw error;
        return;
      }

      // Otherwise, update existing settings
      const { error } = await supabase
        .from('shipping_settings')
        .update({
          price,
          free_shipping_threshold: freeShippingThreshold,
          updated_at: new Date().toISOString(),
        })
        .eq('id', shippingSettings.id);

      if (error) throw error;
    },
    onSuccess: () => {
      // Invalidera alla queries som kan använda shipping-settings
      queryClient.invalidateQueries();
      toast({
        title: 'Shipping price updated',
        description: 'The shipping price has been updated successfully.',
      });
      setNewPrice('');
      setNewFreeShippingThreshold('');
    },
    onError: error => {
      console.error('Error updating shipping price:', error);
      toast({
        title: 'Error updating shipping price',
        description: 'There was an error updating the shipping price. Please try again.',
        variant: 'destructive',
      });
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const price = parseFloat(newPrice);
    const freeShippingThreshold = parseFloat(newFreeShippingThreshold);
    if (isNaN(price) || price < 0) {
      toast({
        title: 'Invalid price',
        description: 'Please enter a valid positive number.',
        variant: 'destructive',
      });
      return;
    }
    if (!isNaN(freeShippingThreshold) && freeShippingThreshold < 0) {
      toast({
        title: 'Invalid free shipping threshold',
        description: 'Please enter a valid positive number.',
        variant: 'destructive',
      });
      return;
    }
    updateShipping.mutate({
      price,
      freeShippingThreshold: isNaN(freeShippingThreshold) ? undefined : freeShippingThreshold,
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-card rounded-lg shadow p-6">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">Shipping Settings</h2>
        <p className="text-muted-foreground">Manage shipping prices for your store</p>
      </div>
      <div className="mt-6">
        <div className="max-w-md">
          <p className="mb-4">Current shipping price: {shippingSettings?.price} SEK</p>
          <p className="mb-4">
            Current free shipping threshold: {shippingSettings?.free_shipping_threshold} SEK
          </p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex gap-4">
              <Input
                type="number"
                step="0.01"
                min="0"
                placeholder="New shipping price"
                value={newPrice}
                onChange={e => setNewPrice(e.target.value)}
              />
              <Input
                type="number"
                step="0.01"
                min="0"
                placeholder="New free shipping threshold"
                value={newFreeShippingThreshold}
                onChange={e => setNewFreeShippingThreshold(e.target.value)}
              />
              <Button type="submit" disabled={updateShipping.isPending}>
                Update Price
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

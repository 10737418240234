import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import OnboardingStepComponent from '@/components/onboarding/OnboardingStep';
import { supabase } from '@/integrations/supabase/client';
import { Database } from '@/lib/supabase/database.types.ts';

// Interface matching DB table (assuming it's created and in types)
type OnboardingStepData = Database['public']['Tables']['onboarding_steps']['Row'];

const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();

  // Fetch active onboarding steps from Supabase only
  const {
    data: steps,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['onboarding-steps-active'],
    queryFn: async (): Promise<OnboardingStepData[]> => {
      const { data, error } = await supabase
        .from('onboarding_steps')
        .select('*')
        .eq('active', true)
        .order('step_number', { ascending: true });

      if (error) {
        console.error('Error fetching onboarding steps from DB:', error);
        throw error;
      }
      return data || [];
    },
  });

  useEffect(() => {
    // Set page title in English
    document.title = 'How Pundeez Works | Pundeez';
  }, []);

  const handleNext = () => {
    if (currentStep === steps.length) {
      // Om det är sista steget, navigera tillbaka till startsidan
      navigate('/');
    } else {
      // Annars gå till nästa steg
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Handle Loading State
  if (isLoading) {
    return (
      <div className="min-h-screen bg-background flex flex-col items-center justify-center p-4">
        <h1 className="text-2xl font-bold mb-4">Loading Onboarding...</h1>
      </div>
    );
  }

  // Handle Error State
  if (isError) {
    return (
      <div className="min-h-screen bg-background flex flex-col items-center justify-center p-4">
        <h1 className="text-2xl font-bold mb-4 text-destructive">Error Loading Onboarding</h1>
        <p className="text-muted-foreground mb-6">
          Could not load the onboarding steps. Please try again later.
        </p>
        <button
          onClick={() => navigate('/')}
          className="px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90"
        >
          Back to Home
        </button>
      </div>
    );
  }

  // Handle No Active Steps Found State
  if (!steps || steps.length === 0) {
    return (
      <div className="min-h-screen bg-background flex flex-col items-center justify-center p-4">
        <h1 className="text-2xl font-bold mb-4">Onboarding Unavailable</h1>
        <p className="text-muted-foreground mb-6">
          There are no active onboarding steps available at the moment.
        </p>
        <button
          onClick={() => navigate('/')}
          className="px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90"
        >
          Back to Home
        </button>
      </div>
    );
  }

  // Ensure currentStep calculation uses the fetched steps length
  const currentStepsArray = Array.isArray(steps) ? steps : [];
  const safeCurrentStep = Math.min(currentStep, currentStepsArray.length);
  const currentStepData =
    currentStepsArray.length > 0 ? currentStepsArray[safeCurrentStep - 1] : null;

  // Check if currentStepData exists before rendering
  if (!currentStepData) {
    return (
      <div className="min-h-screen bg-background flex flex-col items-center justify-center p-4">
        <h1 className="text-2xl font-bold mb-4 text-destructive">Error</h1>
        <p className="text-muted-foreground mb-6">Could not display the current onboarding step.</p>
        <button
          onClick={() => navigate('/')}
          className="px-4 py-2 bg-primary text-primary-foreground rounded-md hover:bg-primary/90"
        >
          Back to Home
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <main className="flex-1 flex items-center justify-center py-12">
        <AnimatePresence mode="wait">
          <OnboardingStepComponent
            key={safeCurrentStep}
            title={currentStepData.title}
            description={currentStepData.description}
            imageUrl={currentStepData.image_url || ''}
            stepNumber={safeCurrentStep}
            totalSteps={currentStepsArray.length}
            onNext={handleNext}
            onPrevious={handlePrevious}
            isLastStep={safeCurrentStep === currentStepsArray.length}
          />
        </AnimatePresence>
      </main>
    </div>
  );
};

export default Onboarding;

import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from './ui/button';
import { X } from 'lucide-react';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    // Check if user has already consented
    const hasConsented = localStorage.getItem('cookieConsent');
    if (!hasConsented) {
      // Show the consent banner after a short delay
      const timer = setTimeout(() => {
        setShowConsent(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem('cookieConsent', 'all');
    setShowConsent(false);
  };

  const handleAcceptNecessary = () => {
    localStorage.setItem('cookieConsent', 'necessary');
    setShowConsent(false);
  };

  const handleCustomize = () => {
    // This would typically open a modal with detailed cookie settings
    // For now, we'll just accept necessary cookies
    handleAcceptNecessary();
  };

  if (!showConsent) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        className="fixed bottom-0 left-0 right-0 z-50 bg-background/80 backdrop-blur-sm border-t border-border shadow-lg"
      >
        <div className="container mx-auto px-4 py-4 md:py-6">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
            <div className="flex-1 pr-8">
              <h3 className="text-lg font-semibold mb-2">🍪 Cookie Settings</h3>
              <p className="text-sm text-muted-foreground">
                We use cookies to enhance your browsing experience, serve personalized content, and
                analyze our traffic. By clicking "Accept All", you consent to our use of cookies.
                <button onClick={handleCustomize} className="text-primary hover:underline ml-1">
                  Read more about our cookie policy
                </button>
              </p>
            </div>

            <div className="flex flex-col sm:flex-row gap-3 w-full md:w-auto">
              <Button
                variant="outline"
                size="sm"
                onClick={handleAcceptNecessary}
                className="flex-1 sm:flex-none whitespace-nowrap"
              >
                Necessary Only
              </Button>
              <Button
                variant="default"
                size="sm"
                onClick={handleAcceptAll}
                className="flex-1 sm:flex-none whitespace-nowrap"
              >
                Accept All
              </Button>
            </div>

            <button
              onClick={handleAcceptNecessary}
              className="absolute top-4 right-4 text-muted-foreground hover:text-foreground md:hidden"
              aria-label="Close cookie consent"
            >
              <X size={20} />
            </button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CookieConsent;

import { AdminAuthForm } from '@/components/AdminAuthForm';
import { useAuth } from '@/hooks/useAuth';
import { Navigate, Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';

export default function AdminAuth() {
  const { session, isAdmin } = useAuth();

  if (session && isAdmin) {
    return <Navigate to="/admin/dashboard" replace />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-background">
      <div className="relative p-8 bg-card rounded-lg shadow-lg w-full max-w-md border border-border">
        <div className="absolute top-4 left-4">
          <Button variant="ghost" size="sm" asChild>
            <Link to="/" className="flex items-center gap-1">
              <ArrowLeft size={16} />
              <span>Tillbaka</span>
            </Link>
          </Button>
        </div>
        <h1 className="text-2xl font-bold text-center mb-6 text-foreground">Admin Login</h1>
        <AdminAuthForm />
      </div>
    </div>
  );
}

import { useCart } from '@/contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { formatPrice } from '@/lib/utils';
import { ArrowLeft } from 'lucide-react';
import { useState } from 'react';
import { Input } from '@/components/ui/input';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { useQuery } from '@tanstack/react-query';
import { ShippingSettings } from '@/types/shipping';

interface ShippingFormData {
  name: string;
  email: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
}

export default function Checkout() {
  const { cartItems } = useCart();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<ShippingFormData>({
    name: '',
    email: '',
    address: '',
    city: '',
    postalCode: '',
    country: '',
  });

  // Fetch shipping settings
  const { data: shippingSettings } = useQuery<ShippingSettings>({
    queryKey: ['shipping-settings'],
    queryFn: async () => {
      const { data, error } = await supabase.from('shipping_settings').select('*').maybeSingle();

      if (error) throw error;
      return data || { price: 99 }; // Fallback to default price if no settings found
    },
    staleTime: 0, // Alltid hämta färsk data
    gcTime: 0, // Inaktivera caching helt (i React Query v5 används gcTime istället för cacheTime)
  });

  const subtotal = cartItems.reduce((total, item) => total + item.quantity * item.product.price, 0);

  // Beräkna shipping baserat på free shipping threshold
  const shippingAmount =
    shippingSettings?.free_shipping_threshold &&
    subtotal >= shippingSettings.free_shipping_threshold
      ? 0
      : (shippingSettings?.price ?? 99);

  const total = subtotal + shippingAmount;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!cartItems.length) {
      toast.error('Your cart is empty');
      return;
    }

    try {
      setIsLoading(true);

      const { data, error } = await supabase.functions.invoke('create-payment-intent', {
        body: {
          cartItems,
          customerInfo: {
            customer_name: formData.name,
            customer_email: formData.email,
            customer_address: formData.address,
            customer_city: formData.city,
            customer_postal_code: formData.postalCode,
            customer_country: formData.country,
          },
          shippingAmount,
          success_url: `${window.location.origin}/checkout/success`,
          cancel_url: `${window.location.origin}/checkout`,
        },
      });

      if (error) throw error;

      const { sessionId, orderId } = data;

      localStorage.setItem('current_order_id', orderId);
      window.location.href = sessionId;
    } catch (error: any) {
      toast.error(error.message || 'Failed to process checkout. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <div className="max-w-3xl mx-auto py-12 px-4">
        <Button variant="ghost" className="mb-12 hover:bg-secondary" onClick={() => navigate('/')}>
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>

        <div className="grid gap-12">
          <div>
            <h1 className="text-2xl font-normal mb-8 text-foreground">Contact Information</h1>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-4">
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="w-full bg-background border-border focus:border-ring rounded-none focus:ring-0"
                />

                <Input
                  id="name"
                  name="name"
                  placeholder="Full Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="w-full bg-background border-border focus:border-ring rounded-none focus:ring-0"
                />

                <Input
                  id="address"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                  className="w-full bg-background border-border focus:border-ring rounded-none focus:ring-0"
                />

                <div className="grid grid-cols-2 gap-4">
                  <Input
                    id="city"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                    className="w-full bg-background border-border focus:border-ring rounded-none focus:ring-0"
                  />

                  <Input
                    id="postalCode"
                    name="postalCode"
                    placeholder="Postal Code"
                    value={formData.postalCode}
                    onChange={handleInputChange}
                    required
                    className="w-full bg-background border-border focus:border-ring rounded-none focus:ring-0"
                  />
                </div>

                <Input
                  id="country"
                  name="country"
                  placeholder="Country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                  className="w-full bg-background border-border focus:border-ring rounded-none focus:ring-0"
                />
              </div>

              <div className="border-t border-border pt-6">
                <div className="space-y-2">
                  {cartItems.map(item => (
                    <div key={item.id} className="text-sm text-muted-foreground">
                      Size: {item.size}, Color: {item.color}
                      {item.print ? `, Print: ${item.print}` : ''}
                      {item.avatar_id ? <AvatarDisplay avatarId={item.avatar_id} /> : ''}, Quantity:{' '}
                      {item.quantity}
                    </div>
                  ))}
                  <div className="text-sm text-muted-foreground">
                    Subtotal: {formatPrice(subtotal)}
                  </div>
                  <div className="text-sm text-muted-foreground">
                    Shipping: {formatPrice(shippingAmount)}
                  </div>
                  <div className="text-sm font-medium text-foreground">
                    Total: {formatPrice(total)}
                  </div>
                </div>
              </div>

              <Button
                type="submit"
                className="w-full bg-primary text-primary-foreground hover:bg-primary/90 rounded-none"
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'PROCEED TO PAYMENT'}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function AvatarDisplay({ avatarId }: { avatarId: string }) {
  const { data: avatar } = useQuery({
    queryKey: ['avatar', avatarId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('avatars')
        .select('name')
        .eq('id', avatarId)
        .single();

      if (error) throw error;
      return data;
    },
  });

  return avatar ? <>, Avatar: {avatar.name}</> : null;
}

import { motion } from 'framer-motion';
import { BackButton } from '@/components/ui/back-button';
import { useScrollToTop } from '@/hooks/useScrollToTop';

export default function Privacy() {
  useScrollToTop();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-background"
    >
      <div className="max-w-3xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <BackButton />
        <h1 className="text-3xl font-heading mb-8">Privacy Policy</h1>

        <div className="prose prose-gray max-w-none">
          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Information We Collect</h2>
            <p className="text-muted-foreground mb-4">
              We collect information you provide directly to us, including name, email address,
              postal address, phone number, and other information you choose to provide.
            </p>
            <p className="text-muted-foreground mb-4">
              We automatically collect certain information about your device when you use our
              services, including how you interact with our website.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">How We Use Your Information</h2>
            <ul className="list-disc pl-6 text-muted-foreground space-y-2">
              <li>To provide and maintain our services</li>
              <li>To process your transactions</li>
              <li>To send you order confirmations and updates</li>
              <li>To respond to your comments and questions</li>
              <li>To send you marketing communications (with consent)</li>
              <li>To protect against fraudulent or illegal activity</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Information Sharing</h2>
            <p className="text-muted-foreground mb-4">
              We do not sell or rent your personal information to third parties. We may share your
              information with service providers who assist in our operations and services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Cookies and Tracking</h2>
            <p className="text-muted-foreground mb-4">
              We use cookies and similar tracking technologies to track activity on our website and
              hold certain information to improve and analyze our service.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Your Rights</h2>
            <p className="text-muted-foreground mb-4">
              You have the right to access, update, or delete your personal information. You can
              object to processing of your personal data and withdraw consent where applicable.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-heading mb-4">Contact Us</h2>
            <p className="text-muted-foreground">
              If you have any questions about our Privacy Policy, please contact us at{' '}
              <a href="mailto:privacy@pundeez.com" className="text-primary hover:underline">
                privacy@pundeez.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </motion.div>
  );
}

import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '@/components/ui/back-button';
import { useScrollToTop } from '@/hooks/useScrollToTop';

export default function Returns() {
  useScrollToTop();
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen bg-background"
    >
      <div className="max-w-3xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <BackButton />
        <h1 className="text-3xl font-heading mb-8">Returns & Refunds</h1>

        <div className="prose prose-gray max-w-none">
          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Return Policy</h2>
            <p className="text-muted-foreground mb-4">
              We want you to be completely satisfied with your purchase. If you're not entirely
              happy, we accept returns within 30 days of delivery for a full refund or exchange.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Return Requirements</h2>
            <ul className="list-disc pl-6 text-muted-foreground space-y-2">
              <li>Items must be unworn and in original condition</li>
              <li>Original tags must be attached</li>
              <li>Original packaging must be included</li>
              <li>A proof of purchase is required</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">How to Return</h2>
            <div className="bg-card rounded-lg p-6 space-y-4">
              <ol className="list-decimal pl-6 text-muted-foreground space-y-4">
                <li>
                  <strong className="text-foreground">Initiate Return:</strong>
                  <p>Log into your account and select the order you wish to return</p>
                </li>
                <li>
                  <strong className="text-foreground">Print Label:</strong>
                  <p>Download and print the prepaid return shipping label</p>
                </li>
                <li>
                  <strong className="text-foreground">Pack Items:</strong>
                  <p>Securely pack the items in their original packaging</p>
                </li>
                <li>
                  <strong className="text-foreground">Ship:</strong>
                  <p>Drop off the package at your nearest post office</p>
                </li>
              </ol>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Refund Process</h2>
            <p className="text-muted-foreground mb-4">
              Once we receive your return, we'll inspect the items and process your refund within
              3-5 business days. The refund will be issued to your original payment method.
            </p>
            <p className="text-muted-foreground mb-4">
              Please note that shipping costs are non-refundable unless the return is due to our
              error.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-heading mb-4">Exchanges</h2>
            <p className="text-muted-foreground mb-4">
              For exchanges, please return the original item for a refund and place a new order for
              the desired item. This ensures the fastest processing time.
            </p>
          </section>

          <div className="mt-12 space-y-4">
            <p className="text-muted-foreground">
              Need help with your return? Contact our customer service team.
            </p>
            <div className="flex gap-4">
              <Button onClick={() => navigate('/contact')} variant="outline">
                Contact Support
              </Button>
              <Button onClick={() => (window.location.href = 'mailto:returns@pundeez.com')}>
                Email Returns Team
              </Button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

import { formatPrice } from '@/lib/utils';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';

interface OrderItemProps {
  item: {
    id: string;
    product_id: string;
    quantity: number;
    size: string;
    color: string;
    print?: string | null;
    avatar_id?: string | null;
    price_at_time: number;
    product: {
      name: string;
      image_url: string | null;
    };
  };
}

export function OrderItemCard({ item }: OrderItemProps) {
  // Fetch avatar details if avatar_id is present
  const { data: avatar } = useQuery({
    queryKey: ['avatar', item.avatar_id],
    queryFn: async () => {
      if (!item.avatar_id) return null;

      const { data, error } = await supabase
        .from('avatars')
        .select('*')
        .eq('id', item.avatar_id)
        .single();

      if (error) throw error;
      return data;
    },
    enabled: !!item.avatar_id, // Only run query if avatar_id exists
  });

  return (
    <div className="flex items-start gap-6 p-4 border rounded-lg">
      {item.product.image_url && (
        <img
          src={item.product.image_url}
          alt={item.product.name}
          className="w-20 h-20 object-cover rounded"
        />
      )}
      <div className="flex-1 flex flex-col justify-between h-full space-y-2">
        <div>
          <h4 className="font-medium mb-2">{item.product.name}</h4>
          <div className="text-sm text-muted-foreground space-y-1">
            <p>
              <span className="font-medium text-foreground">Size:</span> {item.size}
            </p>
            <p>
              <span className="font-medium text-foreground">Color:</span> {item.color}
            </p>
            <p>
              <span className="font-medium text-foreground">Print:</span>{' '}
              {item.print ? item.print : 'N/A'}
            </p>
            <div className="mt-2">
              <span className="font-medium text-foreground block mb-1">Avatar:</span>{' '}
              {avatar ? (
                <div className="flex items-center gap-2">
                  {avatar.image_url && (
                    <img 
                      src={avatar.image_url} 
                      alt={avatar.name}
                      className="w-8 h-8 object-cover rounded-full" 
                    />
                  )}
                  <span>{avatar.name}</span>
                </div>
              ) : (
                'N/A'
              )}
            </div>
          </div>
        </div>
        <p className="text-sm mt-auto">
          <span className="font-medium text-foreground">Quantity:</span> {item.quantity} ×{' '}
          {formatPrice(item.price_at_time)}
        </p>
      </div>
      <div className="text-right mt-auto">
        <p className="font-medium text-lg">{formatPrice(item.quantity * item.price_at_time)}</p>
      </div>
    </div>
  );
}

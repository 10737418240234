import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ProductManagement } from './ProductManagement';
import { OrdersTable } from './OrdersTable';
import { ShippingManagement } from './ShippingManagement';
import { OnboardingManagement } from './OnboardingManagement';
import { ContentManagement } from './ContentManagement';
import { PrintManagement } from './PrintManagement';
import { AvatarManagement } from './AvatarManagement';

// Komponent för admin-flikar
export function AdminTabs() {
  return (
    <Tabs defaultValue="products" className="space-y-4">
      <TabsList>
        <TabsTrigger value="products">Products</TabsTrigger>
        <TabsTrigger value="prints">Prints</TabsTrigger>
        <TabsTrigger value="avatars">Avatars</TabsTrigger>
        <TabsTrigger value="orders">Orders</TabsTrigger>
        <TabsTrigger value="shipping">Shipping</TabsTrigger>
        <TabsTrigger value="content">Content</TabsTrigger>
        <TabsTrigger value="onboarding">Onboarding</TabsTrigger>
      </TabsList>

      <TabsContent value="products" className="space-y-4">
        <ProductManagement />
      </TabsContent>

      <TabsContent value="prints" className="space-y-4">
        <PrintManagement />
      </TabsContent>

      <TabsContent value="avatars" className="space-y-4">
        <div className="bg-card rounded-lg shadow p-6">
          <div className="space-y-0.5">
            <h2 className="text-2xl font-bold tracking-tight">Avatars</h2>
            <p className="text-muted-foreground">
              Manage avatars that customers can choose for their products
            </p>
          </div>
          <div className="mt-6">
            <AvatarManagement />
          </div>
        </div>
      </TabsContent>

      <TabsContent value="orders" className="space-y-4">
        <div className="bg-card rounded-lg shadow p-6">
          <div className="space-y-0.5">
            <h2 className="text-2xl font-bold tracking-tight">Orders</h2>
            <p className="text-muted-foreground">
              Manage and track all orders placed on your store
            </p>
          </div>
          <div className="mt-6">
            <OrdersTable />
          </div>
        </div>
      </TabsContent>

      <TabsContent value="shipping" className="space-y-4">
        <ShippingManagement />
      </TabsContent>

      <TabsContent value="content" className="space-y-4">
        <ContentManagement />
      </TabsContent>

      <TabsContent value="onboarding" className="space-y-4">
        <OnboardingManagement />
      </TabsContent>
    </Tabs>
  );
}

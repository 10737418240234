import { motion } from 'framer-motion';
import { formatPrice } from '@/lib/utils';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Product } from '@/types/products';

const ProductSection = () => {
  const { data: contents } = useQuery({
    queryKey: ['section-contents', 'product'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('section_content')
        .select('*')
        .eq('section_id', 'product');

      if (error) throw error;
      return Object.fromEntries(data.map(item => [item.content_key, item.content_value]));
    },
  });

  // Hämta den aktuella produkten baserat på ID från localStorage
  const { data: currentProduct } = useQuery({
    queryKey: ['current-product'],
    queryFn: async () => {
      const productId = localStorage.getItem('currentProductId');
      if (!productId) return null;

      const { data, error } = await supabase
        .from('products')
        .select('*')
        .eq('id', productId)
        .single();

      if (error) throw error;
      return data as Product | null;
    },
  });

  const getContent = (key: string) => contents?.[key] || '';

  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
      className="h-full w-full flex items-center justify-center bg-background"
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-col gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            viewport={{ once: true }}
            className="text-center max-w-2xl mx-auto space-y-6"
          >
            <h2 className="text-4xl font-serif">{getContent('title') || 'The Shirt'}</h2>
            <div className="space-y-4">
              <p className="text-lg">
                {getContent('fit_description') || 'The shirt is an oversized boxy fit.'}
              </p>
              <p className="text-muted-foreground">
                {getContent('size_approach') || 'FITTED APPROACH: SIZE DOWN'}
              </p>
              <p className="text-muted-foreground">
                {getContent('intended_wear') || 'INTENDED WEAR: STAY TRUE TO SIZE'}
              </p>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            viewport={{ once: true }}
            className="relative aspect-square w-full max-w-xl overflow-hidden rounded-lg"
          >
            <img
              src={getContent('image_url') || '/default-product.png'}
              alt={getContent('title') || 'Product Image'}
              className="w-full h-full object-cover"
            />
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default ProductSection;

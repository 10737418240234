import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

export interface OnboardingStepProps {
  title: string;
  description: string;
  imageUrl: string;
  stepNumber: number;
  totalSteps: number;
  onNext: () => void;
  onPrevious: () => void;
  isLastStep?: boolean;
}

const OnboardingStep = ({
  title,
  description,
  imageUrl,
  stepNumber,
  totalSteps,
  onNext,
  onPrevious,
  isLastStep = false,
}: OnboardingStepProps) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full max-w-4xl mx-auto px-4 py-8">
      {/* Step-indikator */}
      <div className="flex items-center justify-center w-full mb-8">
        {Array.from({ length: totalSteps }).map((_, index) => (
          <div
            key={index}
            className={cn(
              'h-2 mx-1 rounded-full transition-all duration-300',
              index < stepNumber ? 'w-8 bg-primary' : 'w-4 bg-muted',
              index === stepNumber - 1 && 'animate-pulse'
            )}
          />
        ))}
      </div>

      {/* Bild - Wrapped in motion.div for independent animation */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="relative w-full aspect-video mb-8 overflow-hidden rounded-lg"
      >
        <img
          src={imageUrl}
          alt={`Step ${stepNumber}: ${title}`}
          className="w-full h-full object-cover"
        />
      </motion.div>

      {/* Text Content Wrapper - Grows and centers text */}
      <div className="flex-grow flex flex-col justify-center items-center text-center mt-4 mb-8 w-full max-w-2xl">
        {/* Innehåll */}
        <h2 className="text-3xl md:text-4xl font-heading mb-4 text-center">
          Step {stepNumber}: {title}
        </h2>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="text-lg text-muted-foreground text-center"
        >
          {description}
        </motion.p>
      </div>

      {/* Navigeringsknappar - Pushed to bottom */}
      <div className="flex items-center justify-between w-full max-w-md mt-auto">
        <Button
          variant="outline"
          onClick={onPrevious}
          className="font-mono uppercase tracking-wider"
          disabled={stepNumber === 1}
        >
          Back
        </Button>

        <Button onClick={onNext} className="font-mono uppercase tracking-wider">
          {isLastStep ? 'Finish' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

export default OnboardingStep;

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useEffect, useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { Product } from '@/types/products';

interface ProductFormData {
  name: string;
  gender: string;
  description: string | null;
  sizes: string[];
  colors: string[];
  price: number;
  image_url: string | null;
  images: string[];
}

interface ProductFormDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  product?: Product;
  onSuccess: () => void;
}

const AVAILABLE_SIZES = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
const AVAILABLE_GENDERS = ['Men', 'Women'];
const AVAILABLE_COLORS = ['Black', 'Gray', 'Beige', 'Pink', 'Olive'];

const initialFormData: ProductFormData = {
  name: '',
  gender: 'Men',
  description: null,
  sizes: [],
  colors: [],
  price: 0,
  image_url: null,
  images: [],
};

export function ProductFormDialog({
  open,
  onOpenChange,
  product,
  onSuccess,
}: ProductFormDialogProps) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<ProductFormData>(initialFormData);

  // Reset form when dialog closes
  useEffect(() => {
    if (!open) {
      setFormData(initialFormData);
    }
  }, [open]);

  // Update form data when product changes
  useEffect(() => {
    if (product) {
      setFormData({
        name: product.name,
        gender: product.gender,
        description: product.description,
        sizes: product.sizes,
        colors: product.colors || [],
        price: product.price,
        image_url: product.image_url || null,
        images: product.images || [],
      });
    }
  }, [product]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const dataToSubmit = {
        ...formData,
      };

      if (product?.id) {
        // Update existing product
        const { error } = await supabase.from('products').update(dataToSubmit).eq('id', product.id);

        if (error) throw error;
        toast.success('Product updated successfully');
      } else {
        // Create new product
        const { error } = await supabase.from('products').insert(dataToSubmit);

        if (error) throw error;
        toast.success('Product created successfully');
      }

      onSuccess();
      onOpenChange(false);
    } catch (error: any) {
      console.error('Submission error:', error);
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSizeToggle = (size: string) => {
    setFormData(prev => ({
      ...prev,
      sizes: prev.sizes.includes(size) ? prev.sizes.filter(s => s !== size) : [...prev.sizes, size],
    }));
  };

  const handleColorToggle = (color: string) => {
    setFormData(prev => ({
      ...prev,
      colors: prev.colors.includes(color)
        ? prev.colors.filter(c => c !== color)
        : [...prev.colors, color],
    }));
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[90vw] lg:max-w-[1024px] max-h-[90vh] overflow-y-auto p-6">
        <DialogHeader>
          <DialogTitle>{product ? 'Edit Product' : 'Add New Product'}</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Left Column */}
            <div className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  value={formData.name}
                  onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  required
                  className="w-full"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="gender">Gender</Label>
                <Select
                  value={formData.gender}
                  onValueChange={value => setFormData(prev => ({ ...prev, gender: value }))}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select gender" />
                  </SelectTrigger>
                  <SelectContent>
                    {AVAILABLE_GENDERS.map(gender => (
                      <SelectItem key={gender} value={gender}>
                        {gender}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <Label htmlFor="description">Description</Label>
                <Textarea
                  id="description"
                  value={formData.description || ''}
                  onChange={e =>
                    setFormData(prev => ({
                      ...prev,
                      description: e.target.value || null,
                    }))
                  }
                  className="min-h-[120px] w-full"
                />
              </div>

              <div className="space-y-2">
                <Label>Sizes</Label>
                <div className="flex flex-wrap gap-2 mt-2">
                  {AVAILABLE_SIZES.map(size => (
                    <Button
                      key={size}
                      type="button"
                      size="sm"
                      variant={formData.sizes.includes(size) ? 'default' : 'outline'}
                      onClick={() => handleSizeToggle(size)}
                      className="min-w-[48px]"
                    >
                      {size}
                    </Button>
                  ))}
                </div>
              </div>

              <div className="space-y-2">
                <Label>Colors</Label>
                <div className="flex flex-wrap gap-2 mt-2">
                  {AVAILABLE_COLORS.map(color => (
                    <Button
                      key={color}
                      type="button"
                      size="sm"
                      variant={formData.colors.includes(color) ? 'default' : 'outline'}
                      onClick={() => handleColorToggle(color)}
                      className="min-w-[64px]"
                    >
                      {color}
                    </Button>
                  ))}
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="image_url">Main Image URL</Label>
                <Input
                  id="image_url"
                  value={formData.image_url || ''}
                  onChange={e =>
                    setFormData(prev => ({
                      ...prev,
                      image_url: e.target.value || null,
                    }))
                  }
                  placeholder="Enter Supabase storage URL for the main product image"
                  className="w-full"
                />
                <p className="text-xs text-muted-foreground">
                  Paste the URL from your uploaded main image
                </p>
              </div>

              <div className="space-y-2">
                <Label>Additional Images</Label>
                <div className="space-y-2 max-h-[200px] overflow-y-auto pr-2">
                  {formData.images.map((url, index) => (
                    <div key={index} className="flex gap-2">
                      <Input
                        value={url}
                        onChange={e => {
                          const newImages = [...formData.images];
                          newImages[index] = e.target.value;
                          setFormData(prev => ({ ...prev, images: newImages }));
                        }}
                        placeholder={`Image URL ${index + 1}`}
                        className="flex-1"
                      />
                      <Button
                        type="button"
                        variant="destructive"
                        size="icon"
                        onClick={() => {
                          const newImages = formData.images.filter((_, i) => i !== index);
                          setFormData(prev => ({ ...prev, images: newImages }));
                        }}
                      >
                        ×
                      </Button>
                    </div>
                  ))}
                </div>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setFormData(prev => ({
                      ...prev,
                      images: [...prev.images, ''],
                    }));
                  }}
                  className="mt-2"
                >
                  Add Image
                </Button>
                <p className="text-xs text-muted-foreground">
                  Add additional product images from your storage
                </p>
              </div>

              <div className="space-y-2">
                <Label htmlFor="price">Price</Label>
                <Input
                  id="price"
                  type="number"
                  step="0.01"
                  value={formData.price}
                  onChange={e =>
                    setFormData(prev => ({
                      ...prev,
                      price: parseFloat(e.target.value),
                    }))
                  }
                  required
                  className="w-full"
                />
              </div>
            </div>
          </div>

          <DialogFooter className="mt-8 flex justify-end">
            <Button type="submit" disabled={loading}>
              {loading ? 'Saving...' : 'Save Product'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function BackButton() {
  const navigate = useNavigate();

  return (
    <Button variant="ghost" className="mb-8 hover:bg-secondary" onClick={() => navigate('/')}>
      <ArrowLeft className="mr-2 h-4 w-4" />
      Back
    </Button>
  );
}

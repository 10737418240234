import { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { Loader2 } from 'lucide-react';

interface SectionContent {
  id: string;
  section_id: string;
  content_key: string;
  content_value: string;
}

interface ContentFormProps {
  sectionId: string;
  contents: SectionContent[];
  isLoading: boolean;
  onUpdate: (content: { content_key: string; content_value: string }) => void;
}

const ContentForm = ({ sectionId, contents, isLoading, onUpdate }: ContentFormProps) => {
  const [formState, setFormState] = useState<Record<string, string>>(() => {
    return contents.reduce(
      (acc, content) => {
        acc[content.content_key] = content.content_value;
        return acc;
      },
      {} as Record<string, string>
    );
  });

  const handleInputChange = (key: string, value: string) => {
    setFormState(prev => ({ ...prev, [key]: value }));
  };

  const handleSave = () => {
    Object.entries(formState).forEach(([key, value]) => {
      const content = contents.find(c => c.content_key === key);
      if (content && content.content_value !== value) {
        onUpdate({ content_key: key, content_value: value });
      }
    });
  };

  const getContentValue = (key: string) => {
    const value = formState[key] || '';
    return value;
  };

  if (sectionId === 'hero') {
    return (
      <div className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="title">Title</Label>
          <Input
            id="title"
            value={getContentValue('title')}
            onChange={e => handleInputChange('title', e.target.value)}
            disabled={isLoading}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="description">Description</Label>
          <Textarea
            id="description"
            value={getContentValue('description')}
            onChange={e => handleInputChange('description', e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="video_url">Background Video URL</Label>
          <Input
            id="video_url"
            value={getContentValue('video_url')}
            onChange={e => handleInputChange('video_url', e.target.value)}
            placeholder="https://example.com/video.mp4"
            disabled={isLoading}
          />
          {getContentValue('video_url') && (
            <div className="mt-2 relative aspect-video w-full overflow-hidden rounded-lg border">
              <video
                src={getContentValue('video_url')}
                autoPlay
                loop
                muted
                controls
                className="w-full h-full object-cover"
                onError={e => {
                  e.currentTarget.classList.add('opacity-50');
                  e.currentTarget.insertAdjacentHTML(
                    'afterend',
                    '<div class="absolute inset-0 flex items-center justify-center text-red-500">Video URL is invalid</div>'
                  );
                }}
              />
            </div>
          )}
          <p className="text-sm text-muted-foreground">
            Video URL för bakgrunden. MP4-format rekommenderas. Lämna tomt för att använda
            bakgrundsbild istället.
          </p>
        </div>

        <div className="space-y-2">
          <Label htmlFor="image_url">Background Image URL (Fallback)</Label>
          <Input
            id="image_url"
            value={getContentValue('image_url')}
            onChange={e => handleInputChange('image_url', e.target.value)}
            placeholder="/uploads/... or https://..."
            disabled={isLoading}
          />
          {getContentValue('image_url') && (
            <div className="mt-2 relative aspect-video w-full overflow-hidden rounded-lg border">
              <img
                src={getContentValue('image_url')}
                alt="Background Preview"
                className="w-full h-full object-cover"
                onError={e => {
                  // Use inline SVG as fallback instead of external image
                  e.currentTarget.src =
                    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
                  e.currentTarget.classList.add('opacity-50');
                }}
              />
            </div>
          )}
          <p className="text-sm text-muted-foreground">
            Optimal resolution: 1920x1080px. Image will be center-cropped to fill the screen.
          </p>
        </div>
        <Button onClick={handleSave} disabled={isLoading} className="w-full">
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Saving...
            </>
          ) : (
            'Save Changes'
          )}
        </Button>
      </div>
    );
  }

  if (sectionId === 'model2') {
    return (
      <div className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="title">Title</Label>
          <Input
            id="title"
            value={getContentValue('title')}
            onChange={e => handleInputChange('title', e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="button_text">Button Text</Label>
          <Input
            id="button_text"
            value={getContentValue('button_text')}
            onChange={e => handleInputChange('button_text', e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="image_url">Desktop Background Image URL</Label>
          <Input
            id="image_url"
            value={getContentValue('image_url')}
            onChange={e => handleInputChange('image_url', e.target.value)}
            placeholder="/uploads/... or https://..."
            disabled={isLoading}
          />
          {getContentValue('image_url') && (
            <div className="mt-2 relative aspect-video w-full overflow-hidden rounded-lg border">
              <img
                src={getContentValue('image_url')}
                alt="Desktop Background Preview"
                className="w-full h-full object-cover"
                onError={e => {
                  // Use inline SVG as fallback instead of external image
                  e.currentTarget.src =
                    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
                  e.currentTarget.classList.add('opacity-50');
                }}
              />
            </div>
          )}
          <p className="text-sm text-muted-foreground">
            Optimal resolution: 1920x1080px. Image will be center-cropped to fill the screen on
            desktop devices.
          </p>
        </div>

        <div className="space-y-2 mt-6">
          <Label htmlFor="mobile_image_url">Mobile Background Image URL</Label>
          <Input
            id="mobile_image_url"
            value={getContentValue('mobile_image_url')}
            onChange={e => handleInputChange('mobile_image_url', e.target.value)}
            placeholder="/uploads/... or https://..."
            disabled={isLoading}
          />
          {getContentValue('mobile_image_url') && (
            <div className="mt-2 relative w-1/2 mx-auto aspect-[9/16] overflow-hidden rounded-lg border">
              <img
                src={getContentValue('mobile_image_url')}
                alt="Mobile Background Preview"
                className="w-full h-full object-cover"
                onError={e => {
                  // Use inline SVG as fallback instead of external image
                  e.currentTarget.src =
                    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
                  e.currentTarget.classList.add('opacity-50');
                }}
              />
            </div>
          )}
          <p className="text-sm text-muted-foreground">
            Optimal resolution: 1080x1920px. This image will be used on mobile devices to avoid
            cropping issues.
          </p>
        </div>

        <Button onClick={handleSave} disabled={isLoading} className="w-full">
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Saving...
            </>
          ) : (
            'Save Changes'
          )}
        </Button>
      </div>
    );
  }

  if (sectionId === 'product') {
    return (
      <div className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="title">Title</Label>
          <Input
            id="title"
            value={getContentValue('title')}
            onChange={e => handleInputChange('title', e.target.value)}
            disabled={isLoading}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="fit_description">Fit Description</Label>
          <Input
            id="fit_description"
            value={getContentValue('fit_description')}
            onChange={e => handleInputChange('fit_description', e.target.value)}
            disabled={isLoading}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="size_approach">Size Approach Text</Label>
          <Input
            id="size_approach"
            value={getContentValue('size_approach')}
            onChange={e => handleInputChange('size_approach', e.target.value)}
            disabled={isLoading}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="intended_wear">Intended Wear Text</Label>
          <Input
            id="intended_wear"
            value={getContentValue('intended_wear')}
            onChange={e => handleInputChange('intended_wear', e.target.value)}
            disabled={isLoading}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="image_url">Product Image URL</Label>
          <Input
            id="image_url"
            value={getContentValue('image_url')}
            onChange={e => handleInputChange('image_url', e.target.value)}
            placeholder="/uploads/... or https://..."
            disabled={isLoading}
          />
          {getContentValue('image_url') && (
            <div className="mt-2 relative aspect-square w-full max-w-[200px] overflow-hidden rounded-lg border">
              <img
                src={getContentValue('image_url')}
                alt="Product Preview"
                className="w-full h-full object-cover"
                onError={e => {
                  // Use inline SVG as fallback instead of external image
                  e.currentTarget.src =
                    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
                  e.currentTarget.classList.add('opacity-50');
                }}
              />
            </div>
          )}
          <p className="text-sm text-muted-foreground">
            Optimal resolution: 800x800px. Image will be center-cropped to maintain square aspect
            ratio.
          </p>
        </div>
        <Button onClick={handleSave} disabled={isLoading} className="w-full">
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Saving...
            </>
          ) : (
            'Save Changes'
          )}
        </Button>
      </div>
    );
  }

  // Ny sektion för GroupSection1
  if (sectionId === 'group1') {
    return (
      <div className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="title">Titel</Label>
          <Input
            id="title"
            value={getContentValue('title')}
            onChange={e => handleInputChange('title', e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="image_url">Desktop Bakgrundsbild URL</Label>
          <Input
            id="image_url"
            value={getContentValue('image_url')}
            onChange={e => handleInputChange('image_url', e.target.value)}
            placeholder="/images/background1.jpg eller https://..."
            disabled={isLoading}
          />
          {getContentValue('image_url') && (
            <div className="mt-2 relative aspect-video w-full overflow-hidden rounded-lg border">
              <img
                src={getContentValue('image_url')}
                alt="Desktop Background Preview"
                className="w-full h-full object-cover"
                onError={e => {
                  // Use inline SVG as fallback instead of external image
                  e.currentTarget.src =
                    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
                  e.currentTarget.classList.add('opacity-50');
                }}
              />
            </div>
          )}
          <p className="text-sm text-muted-foreground">
            Optimal upplösning: 1920x1080px. Bilden kommer att användas på desktop-enheter.
          </p>
        </div>

        <div className="space-y-2 mt-6">
          <Label htmlFor="mobile_image_url">Mobil Bakgrundsbild URL</Label>
          <Input
            id="mobile_image_url"
            value={getContentValue('mobile_image_url')}
            onChange={e => handleInputChange('mobile_image_url', e.target.value)}
            placeholder="/images/mobile-background.jpg eller https://..."
            disabled={isLoading}
          />
          {getContentValue('mobile_image_url') && (
            <div className="mt-2 relative w-1/2 mx-auto aspect-[9/16] overflow-hidden rounded-lg border">
              <img
                src={getContentValue('mobile_image_url')}
                alt="Mobile Background Preview"
                className="w-full h-full object-cover"
                onError={e => {
                  // Use inline SVG as fallback instead of external image
                  e.currentTarget.src =
                    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
                  e.currentTarget.classList.add('opacity-50');
                }}
              />
            </div>
          )}
          <p className="text-sm text-muted-foreground">
            Optimal upplösning: 1080x1920px. Bilden kommer att användas på mobila enheter för att
            undvika beskärning.
          </p>
        </div>

        <Button onClick={handleSave} disabled={isLoading} className="w-full">
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Sparar...
            </>
          ) : (
            'Spara ändringar'
          )}
        </Button>
      </div>
    );
  }

  // Ny sektion för GroupSection2
  if (sectionId === 'group2') {
    return (
      <div className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="title">Titel</Label>
          <Input
            id="title"
            value={getContentValue('title')}
            onChange={e => handleInputChange('title', e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="button_text">Knapptext</Label>
          <Input
            id="button_text"
            value={getContentValue('button_text')}
            onChange={e => handleInputChange('button_text', e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="image_url">Desktop Bakgrundsbild URL</Label>
          <Input
            id="image_url"
            value={getContentValue('image_url')}
            onChange={e => handleInputChange('image_url', e.target.value)}
            placeholder="/images/background2.jpg eller https://..."
            disabled={isLoading}
          />
          {getContentValue('image_url') && (
            <div className="mt-2 relative aspect-video w-full overflow-hidden rounded-lg border">
              <img
                src={getContentValue('image_url')}
                alt="Desktop Background Preview"
                className="w-full h-full object-cover"
                onError={e => {
                  // Use inline SVG as fallback instead of external image
                  e.currentTarget.src =
                    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
                  e.currentTarget.classList.add('opacity-50');
                }}
              />
            </div>
          )}
          <p className="text-sm text-muted-foreground">
            Optimal upplösning: 1920x1080px. Bilden kommer att användas på desktop-enheter.
          </p>
        </div>

        <div className="space-y-2 mt-6">
          <Label htmlFor="mobile_image_url">Mobil Bakgrundsbild URL</Label>
          <Input
            id="mobile_image_url"
            value={getContentValue('mobile_image_url')}
            onChange={e => handleInputChange('mobile_image_url', e.target.value)}
            placeholder="/images/mobile-background.jpg eller https://..."
            disabled={isLoading}
          />
          {getContentValue('mobile_image_url') && (
            <div className="mt-2 relative w-1/2 mx-auto aspect-[9/16] overflow-hidden rounded-lg border">
              <img
                src={getContentValue('mobile_image_url')}
                alt="Mobile Background Preview"
                className="w-full h-full object-cover"
                onError={e => {
                  // Use inline SVG as fallback instead of external image
                  e.currentTarget.src =
                    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
                  e.currentTarget.classList.add('opacity-50');
                }}
              />
            </div>
          )}
          <p className="text-sm text-muted-foreground">
            Optimal upplösning: 1080x1920px. Bilden kommer att användas på mobila enheter för att
            undvika beskärning.
          </p>
        </div>

        <Button onClick={handleSave} disabled={isLoading} className="w-full">
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Sparar...
            </>
          ) : (
            'Spara ändringar'
          )}
        </Button>
      </div>
    );
  }

  // Ny sektion för BackgroundImageSection
  if (sectionId === 'background') {
    return (
      <div className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="image_url">Desktop Bakgrundsbild URL</Label>
          <Input
            id="image_url"
            value={getContentValue('image_url')}
            onChange={e => handleInputChange('image_url', e.target.value)}
            placeholder="/images/background-full.jpg eller https://..."
            disabled={isLoading}
          />
          {getContentValue('image_url') && (
            <div className="mt-2 relative aspect-video w-full overflow-hidden rounded-lg border">
              <img
                src={getContentValue('image_url')}
                alt="Desktop Background Preview"
                className="w-full h-full object-cover"
                onError={e => {
                  // Use inline SVG as fallback instead of external image
                  e.currentTarget.src =
                    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
                  e.currentTarget.classList.add('opacity-50');
                }}
              />
            </div>
          )}
          <p className="text-sm text-muted-foreground">
            Optimal upplösning: 1920x1080px. Bilden kommer att användas på desktop-enheter.
          </p>
        </div>

        <div className="space-y-2 mt-6">
          <Label htmlFor="mobile_image_url">Mobil Bakgrundsbild URL</Label>
          <Input
            id="mobile_image_url"
            value={getContentValue('mobile_image_url')}
            onChange={e => handleInputChange('mobile_image_url', e.target.value)}
            placeholder="/images/mobile-background.jpg eller https://..."
            disabled={isLoading}
          />
          {getContentValue('mobile_image_url') && (
            <div className="mt-2 relative w-1/2 mx-auto aspect-[9/16] overflow-hidden rounded-lg border">
              <img
                src={getContentValue('mobile_image_url')}
                alt="Mobile Background Preview"
                className="w-full h-full object-cover"
                onError={e => {
                  // Use inline SVG as fallback instead of external image
                  e.currentTarget.src =
                    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
                  e.currentTarget.classList.add('opacity-50');
                }}
              />
            </div>
          )}
          <p className="text-sm text-muted-foreground">
            Optimal upplösning: 1080x1920px. Bilden kommer att användas på mobila enheter för att
            undvika beskärning.
          </p>
        </div>

        <Button onClick={handleSave} disabled={isLoading} className="w-full">
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Sparar...
            </>
          ) : (
            'Spara ändringar'
          )}
        </Button>
      </div>
    );
  }

  return null;
};

export function ContentManagement() {
  const [activeSection, setActiveSection] = useState('hero');
  const queryClient = useQueryClient();

  const { data: contents = [], isLoading: isLoadingContents } = useQuery({
    queryKey: ['section-contents', activeSection],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('section_content')
        .select('*')
        .eq('section_id', activeSection)
        .order('content_key');

      if (error) throw error;
      return data as SectionContent[];
    },
  });

  const updateContent = useMutation({
    mutationFn: async ({ section_id, content_key, content_value }: Partial<SectionContent>) => {
      // First check if the content exists
      const { data: existing } = await supabase
        .from('section_content')
        .select('*')
        .eq('section_id', section_id)
        .eq('content_key', content_key)
        .single();

      if (!existing) {
        // If it doesn't exist, insert it
        const { data, error } = await supabase
          .from('section_content')
          .insert([{ section_id, content_key, content_value }]);

        if (error) throw error;
        return data;
      } else {
        // If it exists, update it
        const { data, error } = await supabase
          .from('section_content')
          .update({ content_value })
          .eq('section_id', section_id)
          .eq('content_key', content_key);

        if (error) throw error;
        return data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['section-contents', activeSection] });
      toast.success('Content updated successfully');
    },
    onError: error => {
      toast.error('Failed to update content');
    },
  });

  const handleContentUpdate = async (content: { content_key: string; content_value: string }) => {
    try {
      await updateContent.mutateAsync({
        section_id: activeSection,
        ...content,
      });
      toast.success('Content updated successfully');
      queryClient.invalidateQueries({ queryKey: ['section-contents', activeSection] });
    } catch (error) {
      toast.error('Failed to update content. Please try again.');
    }
  };

  return (
    <div className="bg-card rounded-lg shadow p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Content Management</h2>
      </div>

      <Tabs value={activeSection} onValueChange={setActiveSection}>
        <TabsList className="grid w-full grid-cols-6">
          <TabsTrigger value="hero">HERO</TabsTrigger>
          <TabsTrigger value="model2">MODEL 2</TabsTrigger>
          <TabsTrigger value="group1">GROUP 1</TabsTrigger>
          <TabsTrigger value="group2">GROUP 2</TabsTrigger>
          <TabsTrigger value="background">BACKGROUND</TabsTrigger>
          <TabsTrigger value="product">PRODUCT</TabsTrigger>
        </TabsList>

        <TabsContent value="hero">
          {isLoadingContents ? (
            <div className="flex items-center justify-center p-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <ContentForm
              sectionId="hero"
              contents={contents}
              isLoading={updateContent.isPending}
              onUpdate={handleContentUpdate}
            />
          )}
        </TabsContent>

        <TabsContent value="model2">
          {isLoadingContents ? (
            <div className="flex items-center justify-center p-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <ContentForm
              sectionId="model2"
              contents={contents}
              isLoading={updateContent.isPending}
              onUpdate={handleContentUpdate}
            />
          )}
        </TabsContent>

        <TabsContent value="group1">
          {isLoadingContents ? (
            <div className="flex items-center justify-center p-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <ContentForm
              sectionId="group1"
              contents={contents}
              isLoading={updateContent.isPending}
              onUpdate={handleContentUpdate}
            />
          )}
        </TabsContent>

        <TabsContent value="group2">
          {isLoadingContents ? (
            <div className="flex items-center justify-center p-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <ContentForm
              sectionId="group2"
              contents={contents}
              isLoading={updateContent.isPending}
              onUpdate={handleContentUpdate}
            />
          )}
        </TabsContent>

        <TabsContent value="background">
          {isLoadingContents ? (
            <div className="flex items-center justify-center p-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <ContentForm
              sectionId="background"
              contents={contents}
              isLoading={updateContent.isPending}
              onUpdate={handleContentUpdate}
            />
          )}
        </TabsContent>

        <TabsContent value="product">
          {isLoadingContents ? (
            <div className="flex items-center justify-center p-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <ContentForm
              sectionId="product"
              contents={contents}
              isLoading={updateContent.isPending}
              onUpdate={handleContentUpdate}
            />
          )}
        </TabsContent>
      </Tabs>
    </div>
  );
}

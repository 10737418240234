import { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { supabase } from '@/integrations/supabase/client';
import { v4 as uuidv4 } from 'uuid';
import { type Avatar } from '@/components/AvatarStepSelector'; // Import Avatar type

export interface CartItem {
  id: string;
  product_id: string;
  size: string;
  color: string;
  print?: string | null;
  avatar_id?: string | null;
  quantity: number;
  product: {
    name: string;
    price: number;
    image_url: string | null;
  };
}

interface CartContextType {
  cartItems: CartItem[];
  selectedAvatar: Avatar | null; // Store the full Avatar object
  setSelectedAvatar: (avatar: Avatar | null) => void; // Update setter name and type
  addToCart: (
    productId: string,
    size: string,
    color: string,
    print?: string | null,
    avatar?: Avatar | null
  ) => Promise<void>;
  updateQuantity: (itemId: string, increment: boolean) => Promise<void>;
  isLoading: boolean;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

const CART_ID_KEY = 'bymatthew_cart_id';

export function CartProvider({ children }: { children: React.ReactNode }) {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cartId, setCartId] = useState<string | null>(null);
  // Renamed the state setter to avoid confusion with the exported function
  const [selectedAvatar, _setSelectedAvatarState] = useState<Avatar | null>(null);

  // Initialize cart ID from localStorage or create new one
  useEffect(() => {
    const initializeCart = async () => {
      try {
        let storedCartId = localStorage.getItem(CART_ID_KEY);

        if (!storedCartId) {
          storedCartId = uuidv4();
          localStorage.setItem(CART_ID_KEY, storedCartId);
        }

        setCartId(storedCartId);
        await fetchCartItems(storedCartId);
      } catch (error) {
        console.error('Error initializing cart:', error);
        toast.error('Failed to initialize cart');
      } finally {
        setIsLoading(false);
      }
    };

    initializeCart();
  }, []);

  const fetchCartItems = async (id: string) => {
    try {
      const { data, error } = await supabase
        .from('cart_items')
        .select(
          `
          *,
          product:products (
            name,
            price,
            image_url
          )
        `
        )
        .eq('cart_id', id);

      if (error) throw error;
      setCartItems(data || []);
    } catch (error) {
      console.error('Error fetching cart items:', error);
      toast.error('Failed to fetch cart items');
    }
  };

  const addToCart = async (
    productId: string,
    size: string,
    color: string,
    print?: string | null,
    avatar?: Avatar | null
  ) => {
    if (!cartId) {
      toast.error('Cart not initialized');
      return;
    }

    try {
      setIsLoading(true);

      // Check if item already exists in cart
      const existingItem = cartItems.find(
        item =>
          item.product_id === productId &&
          item.size === size &&
          item.color === color &&
          item.print === print &&
          item.avatar_id === avatar?.id
      );

      if (existingItem) {
        // Update quantity of existing item
        await updateQuantity(existingItem.id, true);
        return;
      }

      // Add new item to cart
      const { error } = await supabase.from('cart_items').insert([
        {
          product_id: productId,
          size,
          color,
          print,
          avatar_id: avatar?.id,
          cart_id: cartId,
        },
      ]);

      if (error) throw error;

      // Refresh cart items
      await fetchCartItems(cartId);
      toast.success('Added to cart');
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('Failed to add to cart');
    } finally {
      setIsLoading(false);
    }
  };

  const updateQuantity = async (itemId: string, increment: boolean) => {
    if (!cartId) return;

    try {
      setIsLoading(true);
      const item = cartItems.find(i => i.id === itemId);

      if (!item) return;

      const newQuantity = increment ? item.quantity + 1 : item.quantity - 1;

      if (newQuantity === 0) {
        // Delete item if quantity would be 0
        const { error } = await supabase
          .from('cart_items')
          .delete()
          .eq('id', itemId)
          .eq('cart_id', cartId);

        if (error) throw error;
      } else {
        // Update quantity
        const { error } = await supabase
          .from('cart_items')
          .update({ quantity: newQuantity })
          .eq('id', itemId)
          .eq('cart_id', cartId);

        if (error) throw error;
      }

      // Refresh cart items
      await fetchCartItems(cartId);
    } catch (error) {
      console.error('Error updating quantity:', error);
      toast.error('Failed to update quantity');
    } finally {
      setIsLoading(false);
    }
  };

  // Custom setter function with logging
  const setSelectedAvatar = (avatar: Avatar | null) => {
    console.log('[CartContext] setSelectedAvatar called with:', avatar);
    _setSelectedAvatarState(avatar);
    // Potentially save to localStorage or backend if persistence is needed
    // localStorage.setItem('selectedAvatar', JSON.stringify(avatar));
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        selectedAvatar, // Provide the avatar object
        setSelectedAvatar, // Provide the custom setter
        addToCart,
        updateQuantity,
        isLoading,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
}

import { motion, AnimatePresence } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from './ui/button';
import { Product } from '@/types/products';
import { Print } from '@/types/prints';

const Model1 = () => {
  const [selectedGender, setSelectedGender] = useState<'Men' | 'Women'>('Men');
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [selectedPrint, setSelectedPrint] = useState<string | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);

  // Hämta alla produkter
  const { data: products, isLoading } = useQuery({
    queryKey: ['products', selectedGender],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('products')
        .select('*')
        .eq('gender', selectedGender);

      if (error) throw error;
      return data as Product[];
    },
  });

  // Hämta alla prints
  const { data: prints } = useQuery({
    queryKey: ['prints'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('prints')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data as Print[];
    },
  });

  // Listen for gender changes from localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const gender = localStorage.getItem('selectedGender') as 'Men' | 'Women' | null;
      if (gender && gender !== selectedGender) {
        setSelectedGender(gender);
        setCurrentProductIndex(0); // Reset product index when gender changes
      }
    };

    // Set initial value
    handleStorageChange();

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [selectedGender]);

  const currentProduct = products?.[currentProductIndex];

  // Pass product id to window for OverlayControls
  useEffect(() => {
    if (currentProduct?.id) {
      window.localStorage.setItem('currentProductId', currentProduct.id);
      // Spara hela produkten för att få tillgång till print
      window.localStorage.setItem('currentProduct', JSON.stringify(currentProduct));
      // Dispatch event to notify other components
      window.dispatchEvent(new Event('storage'));
    }
  }, [currentProduct?.id, currentProduct]);

  // Reset image index when product changes
  useEffect(() => {
    setCurrentImageIndex(0);
  }, [currentProduct?.id]);

  // Set first available color as default when product changes
  useEffect(() => {
    if (currentProduct?.colors?.length > 0 && !selectedColor) {
      setSelectedColor(currentProduct.colors[0]);
    }
  }, [currentProduct?.colors, selectedColor]);

  // Pass selected color to localStorage
  useEffect(() => {
    if (selectedColor) {
      localStorage.setItem('selectedColor', selectedColor);
      window.dispatchEvent(new Event('storage'));
    }
  }, [selectedColor]);

  const allImages = currentProduct
    ? [currentProduct.image_url, ...(currentProduct.images || [])].filter(Boolean)
    : [];

  const nextImage = () => {
    setCurrentImageIndex(prev => (prev === allImages.length - 1 ? 0 : prev + 1));
  };

  const previousImage = () => {
    setCurrentImageIndex(prev => (prev === 0 ? allImages.length - 1 : prev - 1));
  };

  const nextProduct = () => {
    if (products && currentProductIndex < products.length - 1) {
      setCurrentProductIndex(prev => prev + 1);
    }
  };

  const previousProduct = () => {
    if (currentProductIndex > 0) {
      setCurrentProductIndex(prev => prev - 1);
    }
  };

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="min-h-screen flex items-center bg-background snap-start"
    >
      <div className="w-full px-8 h-[calc(100vh-8rem)] md:h-[calc(100vh-12rem)] relative pt-0 md:pt-4">
        <div className="absolute left-4 top-1/2 mt-12 md:mt-0 transform -translate-y-1/2 z-10">
          {currentProductIndex > 0 && (
            <Button onClick={previousProduct} variant="ghost" size="icon">
              <ChevronLeft className="h-8 w-8" />
            </Button>
          )}
        </div>
        <div className="absolute right-4 top-1/2 mt-12 md:mt-0 transform -translate-y-1/2 z-10">
          {products && currentProductIndex < products.length - 1 && (
            <Button onClick={nextProduct} variant="ghost" size="icon">
              <ChevronRight className="h-8 w-8" />
            </Button>
          )}
        </div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="w-full h-full flex flex-col justify-center"
        >
          <div className="grid md:grid-cols-2 gap-4 md:gap-12 items-center">
            <AnimatePresence mode="wait">
              <motion.div
                key={`${selectedGender}-${currentProduct?.id}-${currentImageIndex}`}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                transition={{ duration: 0.5 }}
                className="relative aspect-square overflow-hidden mb-0 md:mb-0"
              >
                {allImages.length > 0 ? (
                  <AnimatePresence mode="wait">
                    {allImages[currentImageIndex] && (
                      <motion.div
                        key={`${selectedGender}-${currentProduct?.id}-${currentImageIndex}`}
                        initial={{ opacity: 0, x: 0 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={0.2}
                        onDragEnd={(e, { offset, velocity }) => {
                          const swipe = offset.x;

                          if (Math.abs(velocity.x) > 20) {
                            if (swipe < 0) {
                              nextImage();
                            } else {
                              previousImage();
                            }
                          }
                        }}
                        className="touch-pan-y"
                      >
                        <motion.img
                          src={allImages[currentImageIndex]}
                          alt={`${currentProduct?.name} view ${currentImageIndex + 1}`}
                          className="object-cover w-full h-full select-none"
                          draggable={false}
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                ) : (
                  <div className="absolute inset-0 flex items-center justify-center text-gray-400">
                    Loading...
                  </div>
                )}
                {allImages.length > 1 && (
                  <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex gap-1.5">
                    {allImages.map((_, index) => (
                      <button
                        key={index}
                        onClick={() => setCurrentImageIndex(index)}
                        className={`w-2 h-2 rounded-full transition-all ${
                          index === currentImageIndex
                            ? 'bg-foreground scale-110'
                            : 'bg-foreground/30 hover:bg-foreground/50'
                        }`}
                        aria-label={`View image ${index + 1}`}
                      />
                    ))}
                  </div>
                )}
              </motion.div>
            </AnimatePresence>

            <AnimatePresence mode="wait">
              <motion.div
                key={selectedGender}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.5 }}
              >
                <motion.h2
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                  className="text-2xl md:text-4xl font-serif mb-3 md:mb-6"
                >
                  {isLoading ? 'Loading...' : currentProduct?.name || 'Product Not Found'}
                </motion.h2>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.8, delay: 0.3 }}
                  className="text-gray-600 dark:text-gray-400 mb-4 md:mb-6 text-sm md:text-base"
                >
                  {currentProduct?.description || 'No description available'}
                </motion.p>

                {currentProduct?.colors && currentProduct.colors.length > 0 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    className="mb-4 md:mb-6 relative z-40"
                  >
                    <h3 className="text-sm font-medium mb-2 md:mb-3">Colors</h3>
                    <div className="flex gap-2 md:gap-3">
                      {currentProduct.colors.map(color => (
                        <motion.button
                          key={color}
                          onClick={() => setSelectedColor(color)}
                          className={`
                            w-10 h-10 rounded-full border-2 transition-all duration-300
                            ${
                              selectedColor === color
                                ? 'border-foreground ring-2 ring-offset-2 ring-foreground/50'
                                : 'border-transparent hover:border-foreground/30'
                            }
                            flex items-center justify-center
                            touch-manipulation
                            relative z-40
                          `}
                          style={{
                            backgroundColor: color,
                            boxShadow:
                              selectedColor === color ? '0 0 0 2px rgba(0,0,0,0.1)' : 'none',
                          }}
                          aria-label={`Select ${color} color`}
                        >
                          {/* Optional: Add a checkmark for selected color */}
                          {selectedColor === color && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className={`
                                text-white
                                ${
                                  color === '#FFFFFF' || color.toLowerCase() === 'white'
                                    ? 'text-black'
                                    : 'text-white'
                                }
                              `}
                            >
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          )}
                        </motion.button>
                      ))}
                    </div>
                  </motion.div>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Model1;

import { X, Plus, Minus } from 'lucide-react';
import { motion } from 'framer-motion';
import { useCart } from '@/contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '@/lib/utils';

interface CartOverlayProps {
  isOpen: boolean;
  onClose: () => void;
}

const CartOverlay = ({ isOpen, onClose }: CartOverlayProps) => {
  const { cartItems, updateQuantity, isLoading } = useCart();
  const navigate = useNavigate();

  const subtotal = cartItems.reduce((total, item) => {
    return total + item.product.price * item.quantity;
  }, 0);

  const handleCheckout = () => {
    onClose();
    navigate('/checkout');
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: '100%' }}
      animate={{ opacity: isOpen ? 1 : 0, x: isOpen ? 0 : '100%' }}
      transition={{ type: 'tween', duration: 0.3 }}
      className={`fixed inset-0 z-50 ${isOpen ? 'pointer-events-auto' : 'pointer-events-none'}`}
    >
      <div className="absolute right-0 top-0 h-full w-full max-w-md bg-white">
        <div className="flex h-full flex-col">
          <div className="p-6">
            <div className="flex justify-between items-center border-b border-gray-200 pb-4">
              <h2 className="text-xl font-serif text-black">Shopping Bag</h2>
              <button onClick={onClose} className="text-black hover:opacity-70 transition-opacity">
                <X size={24} />
              </button>
            </div>

            {isLoading ? (
              <div className="flex items-center justify-center h-32">
                <span className="text-gray-500">Loading...</span>
              </div>
            ) : cartItems.length > 0 ? (
              <div className="mt-6 space-y-6">
                {cartItems.map(item => (
                  <div key={item.id} className="flex gap-4">
                    <div className="w-24 h-24 bg-gray-100">
                      <img
                        src={item.product.image_url || '/placeholder.svg'}
                        alt={item.product.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="flex-1">
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="font-serif text-black">{item.product.name}</h3>
                          <p className="text-sm text-gray-600">/ {item.size}</p>
                        </div>
                        <p className="font-serif text-black">{formatPrice(item.product.price)}</p>
                      </div>

                      <div className="mt-4">
                        <p className="text-sm text-black mb-2">QUANTITY</p>
                        <div className="flex items-center gap-4">
                          <button
                            onClick={() => updateQuantity(item.id, false)}
                            className="text-black hover:opacity-70 transition-opacity"
                          >
                            <Minus size={20} />
                          </button>
                          <span className="w-8 text-center text-black">{item.quantity}</span>
                          <button
                            onClick={() => updateQuantity(item.id, true)}
                            className="text-black hover:opacity-70 transition-opacity"
                          >
                            <Plus size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center text-gray-500 mt-12">Your bag is empty</div>
            )}
          </div>

          {cartItems.length > 0 && (
            <div className="mt-auto border-t border-gray-200">
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <span className="font-serif text-black">SUB TOTAL</span>
                  <span className="font-serif text-black">{formatPrice(subtotal)}</span>
                </div>
                <button
                  onClick={handleCheckout}
                  className="w-full bg-black text-white py-4 hover:bg-gray-800 transition-colors font-serif"
                >
                  Checkout
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default CartOverlay;

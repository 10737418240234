import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { cn } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '@/hooks/use-mobile';
import AvatarStepSelector from './AvatarStepSelector';
import { Button } from '@/components/ui/button';
import { useCart } from '@/contexts/CartContext'; // Import useCart hook
import { Avatar } from './AvatarStepSelector'; // Import Avatar type

const Model2 = () => {
  const [showAvatarSelector, setShowAvatarSelector] = useState(false);

  // Get selectedAvatar from context
  const { selectedAvatar, setSelectedAvatar } = useCart();

  const { data: contents = [] } = useQuery({
    queryKey: ['section-contents', 'model2'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('section_content')
        .select('*')
        .eq('section_id', 'model2');

      if (error) throw error;
      return data;
    },
  });

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const getContent = (key: string) => {
    const content = contents.find(c => c.content_key === key);
    return content?.content_value || '';
  };

  // Choose the appropriate background image based on device type
  const desktopImage =
    getContent('image_url') ||
    'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E';
  const mobileImage = getContent('mobile_image_url');
  const backgroundImage = isMobile && mobileImage ? mobileImage : desktopImage;

  const title = getContent('title') || 'Be your own superhero';
  const buttonText = getContent('button_text') || 'CHOOSE YOUR AVATAR';

  const handleOpenAvatarSelector = () => {
    setShowAvatarSelector(true);
  };

  // Update to accept an Avatar and store it in context
  const handleAvatarSelected = (avatar: Avatar) => {
    setSelectedAvatar(avatar);
    setShowAvatarSelector(false);
  };

  const handleCancelSelection = () => {
    setShowAvatarSelector(false);
  };

  // Handler for the Change Avatar button
  const handleChangeAvatar = () => {
    setShowAvatarSelector(true);
  };

  const description = getContent('description') || 'Create your own superhero and join the battle for justice!';

  return (
    <>
      {showAvatarSelector && (
        <section className="relative w-full min-h-screen flex items-center justify-center bg-black">
          <AvatarStepSelector
            onSelect={handleAvatarSelected}
            onCancel={handleCancelSelection}
          />
        </section>
      )}

      {/* Show selected avatar or default content based on context */}
      {!showAvatarSelector && selectedAvatar ? (
        // Display the selected avatar's image and name with black background
        <motion.section
          key="selected-avatar-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="relative w-full min-h-screen flex flex-col items-center justify-center bg-black overflow-hidden"
        >
          <div className="relative z-10 flex flex-col items-center justify-center text-center max-w-5xl mx-auto px-4">
            {selectedAvatar.image_url && (
              <motion.img
                src={selectedAvatar.image_url}
                alt={selectedAvatar.name}
                className="max-h-[60vh] md:max-h-[70vh] w-auto object-contain mx-auto mb-8 rounded-lg shadow-xl"
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.6, ease: 'easeOut' }}
              />
            )}
            
            <motion.h2
              className="text-4xl md:text-5xl font-bold text-white mb-8 tracking-tight drop-shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              style={{
                textShadow: '0px 2px 4px rgba(0,0,0,0.8), 0px 4px 8px rgba(0,0,0,0.5)',
              }}
            >
              {selectedAvatar.name}
            </motion.h2>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.5 }}
            >
              <Button 
                variant="outline"
                size="lg"
                onClick={handleChangeAvatar}
                className={cn(
                  'bg-white text-black border-transparent',
                  'hover:bg-gray-100 hover:text-black font-mono uppercase tracking-wider',
                  'rounded-full px-8 backdrop-blur-sm transition-all',
                )}
              >
                CHANGE AVATAR
              </Button>
            </motion.div>
          </div>
        </motion.section>
      ) : !showAvatarSelector && (
        // Default content when no avatar is selected
        <motion.section
          key="model2-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.2 }}
          viewport={{ once: true }}
          className={cn(
            'relative h-full w-full bg-background bg-cover bg-center bg-no-repeat',
            'animate-fadeIn'
          )}
          style={{ backgroundImage: `url("${backgroundImage}")` }}
        >
          <div className="container mx-auto px-4 h-full flex items-end justify-center pb-16 md:pb-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: 'easeOut', delay: 0.5 }}
              className="max-w-4xl mx-auto text-center relative"
            >
              <motion.h2
                key="main-title"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.7, ease: 'easeOut' }}
                className={cn(
                  'text-3xl md:text-5xl font-heading mb-6 text-white',
                  'animate-slideIn',
                  'drop-shadow-[0_5px_5px_rgba(0,0,0,0.8)]',
                  'text-stroke-sm'
                )}
                style={{
                  textShadow: '0px 2px 4px rgba(0,0,0,0.8), 0px 4px 8px rgba(0,0,0,0.5)',
                }}
              >
                {title}
              </motion.h2>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.9, ease: 'easeOut' }}
                className="mt-8 animate-slideIn"
              >
                <Button
                  variant="outline"
                  size="lg"
                  onClick={handleOpenAvatarSelector}
                  className={cn(
                    'bg-white text-black',
                    'hover:bg-white hover:text-black font-mono uppercase tracking-wider',
                    'rounded-full px-8 transition-all',
                    'shadow-lg'
                  )}
                >
                  {buttonText}
                </Button>
              </motion.div>
            </motion.div>
          </div>
        </motion.section>
      )}
    </>
  );
};

export default Model2;

import { motion } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Button } from './ui/button';
import { useNavigate } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { useRef, useEffect, useState } from 'react';

/**
 * HeroSection - Huvudkomponenten för herosektionen på startsidan
 *
 * Visar en fullskärmsvideo som bakgrund med överlagrad text och en knapp.
 * Videon spelas automatiskt, är tystad och loopar kontinuerligt.
 * Implementationen säkerställer att videon alltid täcker hela skärmen
 * och spelas upp korrekt på alla enheter.
 */
const HeroSection = () => {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  // Direkt länk till videofilen i Supabase storage
  const videoUrl =
    'https://sfshutidfktywucuduix.supabase.co/storage/v1/object/public/Backgrounds//pundeez.mp4';

  // Hämta innehåll för titeln och beskrivningen från databasen
  const { data: contents = [] } = useQuery({
    queryKey: ['hero-section-contents'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('section_content')
        .select('*')
        .eq('section_id', 'hero');

      if (error) throw error;
      return data;
    },
  });

  // Hantera videoladdning
  useEffect(() => {
    if (!videoRef.current) return;

    // Hantera när videon har laddats
    const handleLoadedData = () => {
      setIsVideoLoaded(true);
      playVideo();
    };

    // Hantera när videon börjar spela
    const handlePlay = () => {
      setIsVideoPlaying(true);
    };

    // Hantera när videon pausas
    const handlePause = () => {
      // Försök spela upp videon igen om den pausas
      if (videoRef.current && isVideoLoaded) {
        playVideo();
      }
    };

    // Hantera eventuella fel
    const handleError = (error: any) => {
      console.error('Video fel:', error);
      setIsVideoLoaded(false);
      // Försök ladda om videon
      if (videoRef.current) {
        videoRef.current.load();
      }
    };

    // Funktion för att spela upp videon
    const playVideo = async () => {
      if (!videoRef.current) return;

      try {
        // Sätt volym till 0 för att säkerställa att videon är tystad
        videoRef.current.volume = 0;
        // Försök spela upp videon
        const playPromise = videoRef.current.play();

        if (playPromise !== undefined) {
          playPromise.catch(error => {
            console.error('Autoplay misslyckades:', error);
            // Om autoplay misslyckas, försök igen när användaren interagerar med sidan
            document.addEventListener(
              'click',
              () => {
                if (videoRef.current && !isVideoPlaying) {
                  videoRef.current
                    .play()
                    .catch(e => console.error('Kunde inte spela upp video efter klick:', e));
                }
              },
              { once: true }
            );
          });
        }
      } catch (error) {
        console.error('Kunde inte spela upp video:', error);
      }
    };

    // Lägg till event listeners
    videoRef.current.addEventListener('loadeddata', handleLoadedData);
    videoRef.current.addEventListener('play', handlePlay);
    videoRef.current.addEventListener('pause', handlePause);
    videoRef.current.addEventListener('error', handleError);

    // Hantera synlighet (när användaren byter flik)
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (videoRef.current && isVideoLoaded && !isVideoPlaying) {
          playVideo();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Försök spela upp videon direkt om den redan är laddad
    if (videoRef.current.readyState >= 3) {
      handleLoadedData();
    }

    // Städa upp event listeners när komponenten avmonteras
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadeddata', handleLoadedData);
        videoRef.current.removeEventListener('play', handlePlay);
        videoRef.current.removeEventListener('pause', handlePause);
        videoRef.current.removeEventListener('error', handleError);
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isVideoLoaded, isVideoPlaying]);

  // Hjälpfunktion för att hämta innehåll från databasen
  const getContent = (key: string) => {
    const content = contents.find(c => c.content_key === key);
    return content?.content_value || '';
  };

  // Navigera till onboarding-sidan när användaren klickar på knappen
  const handleHowItWorks = () => {
    navigate('/onboarding');
  };

  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1.2 }}
      viewport={{ once: true }}
      className="relative w-full h-screen overflow-hidden"
    >
      {/* Videobakgrund som täcker hela skärmen */}
      <div className="absolute inset-0 w-full h-full bg-black">
        <video
          ref={videoRef}
          src={videoUrl}
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
          className="absolute top-0 left-0 w-full h-full object-cover"
          style={{
            objectFit: 'cover',
            objectPosition: 'center center',
            width: '100vw',
            height: '100vh',
            minWidth: '100%',
            minHeight: '100%',
          }}
        />
      </div>

      {/* Innehållscontainer med text och knapp */}
      <div className="relative z-10 flex items-center justify-center w-full h-full">
        <div className="container mx-auto px-4 text-center">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.7, ease: 'easeOut' }}
            className="text-4xl md:text-6xl lg:text-7xl font-serif mb-6 text-white"
            style={{ textShadow: '0 2px 4px rgba(0,0,0,0.3)' }}
          >
            {getContent('title')}
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.9, ease: 'easeOut' }}
            className="text-lg md:text-xl text-gray-100 mb-8 max-w-2xl mx-auto"
            style={{ textShadow: '0 1px 3px rgba(0,0,0,0.3)' }}
          >
            {getContent('description')}
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 1.1, ease: 'easeOut' }}
            className="mt-8"
            style={{ filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.2))' }}
          >
            <Button
              variant="outline"
              size="lg"
              onClick={handleHowItWorks}
              className={cn(
                'bg-white text-black',
                'hover:bg-white hover:text-black font-mono uppercase tracking-wider',
                'rounded-full px-8 transition-all'
              )}
            >
              HOW IT WORKS
            </Button>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default HeroSection;

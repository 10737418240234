import { useAuth } from '@/hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';

export function AdminProtectedRoute() {
  const { session, isAdmin, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!session || !isAdmin) {
    return <Navigate to="/admin/auth" replace />;
  }

  return <Outlet />;
}

import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Pencil, Trash2 } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { Print } from '@/types/prints';

export function PrintManagement() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [printText, setPrintText] = useState('');
  const [selectedPrint, setSelectedPrint] = useState<Print | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  // Hämta alla prints
  const { data: prints, isLoading: isPrintsLoading } = useQuery({
    queryKey: ['prints'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('prints')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        toast.error('Kunde inte hämta prints');
        throw error;
      }

      return data as Print[];
    },
  });

  // Återställ formuläret när dialogen stängs
  useEffect(() => {
    if (!dialogOpen) {
      setPrintText('');
      setSelectedPrint(null);
    }
  }, [dialogOpen]);

  // Fyll i formuläret när en print väljs för redigering
  useEffect(() => {
    if (selectedPrint) {
      setPrintText(selectedPrint.text);
      setDialogOpen(true);
    }
  }, [selectedPrint]);

  // Hantera spara/uppdatera print
  const handleSavePrint = async () => {
    if (!printText.trim()) {
      toast.error('Texten får inte vara tom');
      return;
    }

    setIsLoading(true);

    try {
      if (selectedPrint) {
        // Uppdatera befintlig print
        const { error } = await supabase
          .from('prints')
          .update({ text: printText, updated_at: new Date().toISOString() })
          .eq('id', selectedPrint.id);

        if (error) throw error;
        toast.success('Print uppdaterad');
      } else {
        // Skapa ny print
        const { error } = await supabase.from('prints').insert({ text: printText });

        if (error) throw error;
        toast.success('Print skapad');
      }

      // Uppdatera data och stäng dialogen
      queryClient.invalidateQueries({ queryKey: ['prints'] });
      setDialogOpen(false);
    } catch (error) {
      console.error('Error saving print:', error);
      toast.error('Något gick fel');
    } finally {
      setIsLoading(false);
    }
  };

  // Hantera ta bort print
  const handleDeletePrint = async (print: Print) => {
    if (!confirm(`Är du säker på att du vill ta bort print: "${print.text}"?`)) {
      return;
    }

    try {
      const { error } = await supabase.from('prints').delete().eq('id', print.id);

      if (error) throw error;

      toast.success('Print borttagen');
      queryClient.invalidateQueries({ queryKey: ['prints'] });
    } catch (error) {
      console.error('Error deleting print:', error);
      toast.error('Kunde inte ta bort print');
    }
  };

  return (
    <div className="bg-card rounded-lg shadow p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Print-hantering</h2>
        <Button onClick={() => setDialogOpen(true)}>Lägg till Print</Button>
      </div>

      {/* Prints-tabell */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Text</TableHead>
              <TableHead>Skapad</TableHead>
              <TableHead className="w-[100px]">Åtgärder</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isPrintsLoading ? (
              <TableRow>
                <TableCell colSpan={3} className="text-center py-4">
                  Laddar...
                </TableCell>
              </TableRow>
            ) : prints && prints.length > 0 ? (
              prints.map(print => (
                <TableRow key={print.id}>
                  <TableCell className="font-medium">{print.text}</TableCell>
                  <TableCell>{new Date(print.created_at!).toLocaleDateString('sv-SE')}</TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <Button variant="ghost" size="icon" onClick={() => setSelectedPrint(print)}>
                        <Pencil className="h-4 w-4" />
                      </Button>
                      <Button variant="ghost" size="icon" onClick={() => handleDeletePrint(print)}>
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} className="text-center py-4">
                  Inga prints hittades
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {/* Dialog för att lägga till/redigera print */}
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{selectedPrint ? 'Redigera Print' : 'Lägg till Print'}</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Label htmlFor="print-text">Print-text</Label>
              <Input
                id="print-text"
                value={printText}
                onChange={e => setPrintText(e.target.value)}
                placeholder="Ange print-text"
              />
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setDialogOpen(false)} disabled={isLoading}>
              Avbryt
            </Button>
            <Button onClick={handleSavePrint} disabled={isLoading}>
              {isLoading ? 'Sparar...' : selectedPrint ? 'Uppdatera' : 'Spara'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useCart } from '@/contexts/CartContext';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Skeleton } from '@/components/ui/skeleton';

// Avatar type definition
export interface Avatar {
  id: string;
  name: string;
  image_url?: string; // Optional image URL
}

// Define Props interface
interface AvatarStepSelectorProps {
  onSelect: (avatar: Avatar) => void; // Pass the full Avatar object
  onCancel: () => void; // Callback for the back button
}

const AvatarStepSelector: React.FC<AvatarStepSelectorProps> = ({ onSelect, onCancel }) => {
  // Call useCart() at the top level - REQUIRED by Rules of Hooks
  const { setSelectedAvatar } = useCart();
  const [currentStep, setCurrentStep] = useState(0);

  // Fetch avatars from database
  const {
    data: avatars = [],
    isLoading,
    isError,
  } = useQuery<Avatar[]>({
    queryKey: ['avatars'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('avatars')
        .select('*')
        .order('name', { ascending: true });

      if (error) {
        console.error('Error fetching avatars:', error);
        throw error;
      }
      return data || [];
    },
  });

  // Get the current avatar based on the step
  const currentAvatar = avatars ? avatars[currentStep] : null;

  const totalSteps = avatars ? avatars.length : 0;

  const handleNext = () => {
    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Handler for selecting an avatar
  const handleSelectAvatar = (avatarId: string) => {
    console.log('[AvatarStepSelector] handleSelectAvatar called with ID:', avatarId);

    const selected = avatars?.find(a => a.id === avatarId);
    if (!selected) {
      console.error('[AvatarStepSelector] Selected avatar not found for ID:', avatarId);
      return; // Should not happen if button is enabled correctly
    }

    console.log('[AvatarStepSelector] Found avatar object:', selected);

    // Use the context setter to store the full object
    setSelectedAvatar(selected);
    console.log('[AvatarStepSelector] Called context setSelectedAvatar with:', selected);

    // Call the passed-in onSelect function with the full object
    onSelect(selected);
    console.log('[AvatarStepSelector] Called onSelect prop with:', selected);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full min-h-[400px]">
        <Skeleton className="h-8 w-8" />
      </div>
    );
  }

  if (isError || !currentAvatar) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-white text-center min-h-[400px]">
        <h2 className="text-2xl font-bold mb-4">Error Loading Avatars</h2>
        <p className="mb-6">Could not load avatar information. Please try again later.</p>
        <Button
          variant="outline"
          onClick={onCancel} // Use the new onCancel prop
          className="bg-white text-black hover:bg-gray-200"
        >
          Back
        </Button>
      </div>
    );
  }

  return (
    <motion.div
      key="avatar-selector"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="flex flex-col items-center justify-center w-full h-full max-w-2xl mx-auto px-4 py-8 text-white text-center"
    >
      {/* Image container with fixed height */}
      <div className="flex justify-center items-center mb-4 h-[30vh]">
        {avatars && avatars.length > 0 && currentAvatar && (
          <img
            src={currentAvatar.image_url || '/placeholder.png'} // Provide a fallback image
            alt={currentAvatar.name || 'Avatar'} // Provide fallback alt text
            // Apply styling for max-width, fixed height (30vh), and object-contain
            className="max-w-[750px] w-full h-full object-contain rounded-lg shadow-md"
          />
        )}
      </div>

      {/* Avatar Name - Ensure currentAvatar exists */}
      <AnimatePresence mode="wait">
        {currentAvatar && (
          <motion.h3
            key={currentAvatar.id} // Key change triggers animation
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="text-2xl font-bold mt-4 mb-6"
          >
            {currentAvatar.name}
          </motion.h3>
        )}
      </AnimatePresence>

      {/* Navigation and Selection Buttons */}
      <div className="flex items-center justify-center gap-4 w-full mt-4">
        <Button
          variant="outline"
          size="icon"
          onClick={handlePrevious}
          disabled={currentStep === 0}
          className="rounded-full bg-white/80 hover:bg-white text-black backdrop-blur-sm disabled:opacity-50"
        >
          <ChevronLeft className="h-5 w-5" />
        </Button>

        <Button
          onClick={() => avatars && avatars.length > 0 && handleSelectAvatar(avatars[currentStep].id)}
          disabled={!currentAvatar} // Simplified disabling logic
          className="bg-white text-black font-bold py-3 px-6 rounded-md w-full shadow-md transition-colors hover:bg-gray-100"
        >
          SELECT THIS AVATAR
        </Button>

        <Button
          variant="outline"
          size="icon"
          onClick={handleNext}
          disabled={currentStep === totalSteps - 1}
          className="rounded-full bg-white/80 hover:bg-white text-black backdrop-blur-sm disabled:opacity-50"
        >
          <ChevronRight className="h-5 w-5" />
        </Button>
      </div>

      {/* Back Button */}
      <Button
        variant="link"
        onClick={onCancel} // Use the new onCancel prop
        className="mt-6 text-white/80 hover:text-white"
      >
        Back to Intro
      </Button>
    </motion.div>
  );
};

export default AvatarStepSelector;

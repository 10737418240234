import { Toaster } from '@/components/ui/toaster';
import { Toaster as Sonner } from '@/components/ui/sonner';
import { TooltipProvider } from '@/components/ui/tooltip';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Index from './pages/Index';
import NotFound from './pages/NotFound';
import AdminAuth from './pages/admin/Auth';
import AdminDashboard from './pages/admin/Dashboard';
import { AdminProtectedRoute } from './components/AdminProtectedRoute';
import { supabase } from '@/integrations/supabase/client';
import { useEffect } from 'react';
import { CartProvider } from './contexts/CartContext';
import Checkout from './pages/Checkout';
import CheckoutSuccess from './pages/CheckoutSuccess';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Returns from './pages/Returns';
import Shipping from './pages/Shipping';
import Onboarding from './pages/Onboarding';
import CookieConsent from './components/CookieConsent';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minuter för de flesta queries
      gcTime: 1000 * 60 * 10, // 10 minuter för garbage collection
    },
  },
});

function ThemeInitializer() {
  const { data: settings } = useQuery({
    queryKey: ['app-settings'],
    queryFn: async () => {
      const { data, error } = await supabase.from('app_settings').select('*').single();

      if (error) throw error;
      return data;
    },
  });

  useEffect(() => {
    if (settings?.dark_mode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [settings?.dark_mode]);

  return null;
}

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <CartProvider>
        <BrowserRouter>
          <TooltipProvider>
            <div className="min-h-screen bg-background">
              <ThemeInitializer />
              <Toaster />
              <Sonner position="bottom-right" duration={500} />
              <div className="w-full">
                <Routes>
                  <Route path="/" element={<Index />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/checkout/success" element={<CheckoutSuccess />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/returns" element={<Returns />} />
                  <Route path="/shipping" element={<Shipping />} />
                  <Route path="/onboarding" element={<Onboarding />} />
                  <Route path="/admin/auth" element={<AdminAuth />} />
                  <Route element={<AdminProtectedRoute />}>
                    <Route path="/admin/dashboard" element={<AdminDashboard />} />
                  </Route>
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <CookieConsent />
              </div>
            </div>
          </TooltipProvider>
        </BrowserRouter>
      </CartProvider>
    </QueryClientProvider>
  );
};

export default App;

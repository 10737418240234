import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { ProductsTable } from '@/components/admin/ProductsTable';
import { ProductFormDialog } from '@/components/admin/ProductFormDialog';
import { useQueryClient } from '@tanstack/react-query';
import { Product } from '@/types/products';

export function ProductManagement() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const queryClient = useQueryClient();

  const handleEdit = (product: Product) => {
    setSelectedProduct(product);
    setDialogOpen(true);
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['products'] });
    setSelectedProduct(undefined);
  };

  return (
    <div className="bg-card rounded-lg shadow p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Product Management</h2>
        <Button onClick={() => setDialogOpen(true)}>Add Product</Button>
      </div>

      <ProductsTable onEdit={handleEdit} />

      <ProductFormDialog
        open={dialogOpen}
        onOpenChange={open => {
          setDialogOpen(open);
          if (!open) setSelectedProduct(undefined);
        }}
        product={selectedProduct}
        onSuccess={handleSuccess}
      />
    </div>
  );
}
